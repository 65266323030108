<!-- eslint-disable vue/no-v-html -->
<template>
  <b-form-group
    :class="['FormField', groupClass ]"
    :state="state"
  >
    <!-- :label="label" -->
    <!-- label-cols="3" -->
    <template #label>
      <span v-if="type !== 'toggle'">{{ label }}</span>
      <span
        v-if="hasInfo"
        class="info ml-1"
        @click="openInfo"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="info-circle"
          class="margin-top: -2px; cursor: pointer svg-inline--fa fa-info-circle fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        ><path
          fill="currentColor"
          d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
        /></svg>
      </span>
    </template>
    <b-form-select
      v-if="type === 'select'"
      v-model="internalValue"
      :options="options"
      :state="state"
      :disabled="isDisabled"
      :size="size"
      @input="handleInput"
      @blur="handleBlur"
    />

    <b-form-checkbox-group
      v-else-if="type === 'checkbox'"
      v-model="internalValue"
      :options="options"
      :state="state"
      :disabled="isDisabled"
      :size="size"
      stacked
      @input="handleInput"
      @blur="handleBlur"
    />

    <div
      v-else-if="type === 'true-false'"
      class="FormField--choice d-flex align-items-center w-100"
    >
      <b-form-radio
        v-model="internalValue"
        class="check"
        :state="state"
        :disabled="isDisabled"
        :size="size"
        :value="options[0].value"
      >
        {{ options[0].text }}
      </b-form-radio>
      <b-form-radio
        v-model="internalValue"
        class="ml-3 none"
        :state="state"
        :disabled="isDisabled"
        :size="size"
        :value="options[1].value"
      >
        {{ options[1].text }}
      </b-form-radio>
    </div>

    <b-form-radio-group
      v-else-if="type === 'radio'"
      v-model="internalValue"
      class="FormField--radio d-flex align-items-center w-100"
      :options="options"
      :state="state"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :disabled="selectDisabled || isDisabled"
      :size="size"
      @input="handleInput"
      @blur="handleBlur"
    />

    <b-input-group
      v-else-if="type === 'datepicker'"
    >
      <b-form-datepicker
        ref="datepicker"
        v-model="internalValue"
        :disabled="isDisabled"
        v-bind="datepickerLabels"
        right
        locale="nl-NL"
        @input="handleInput"
        @blur="handleBlur"
      />
      <b-input-group-append
        v-if="isDateCancellable"
        class="d-flex align-items-center datepicker"
        @click="handleResetDatePicker()"
      >
        <b-icon
          icon="x-circle"
          scale="1.3"
          variant="danger"
        />
      </b-input-group-append>
      <b-alert
        v-if="plannedDateAlert && isDateValueCancelled"
        variant="warning"
        class="reset-date-alert"
        :show="true"
        v-html="plannedDateAlert"
      />
    </b-input-group>

    <b-input-group
      v-else-if="type === 'timepicker'"
    >
      <b-form-timepicker
        v-model="internalValue"
        :disabled="isDisabled"
        v-bind="timepickerLabels"
        right
        locale="nl-NL"
        @input="handleInput"
        @blur="handleBlur"
      />
    </b-input-group>

    <b-form-textarea
      v-else-if="type === 'textarea'"
      v-model="internalValue"
      :type="type"
      :state="state"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :disabled="isDisabled"
      :size="size"
      :rows="rows"
      trim
      @input="handleInput"
      @blur="handleBlur"
    />

    <b-form-file
      v-else-if="type === 'upload'"
      v-model="internalValue"
      :state="state"
      :accept="accept"
      :browse-text="browseLabel"
      :placeholder="placeholder || 'Choose a file...'"
      :disabled="isDisabled"
      :drop-placeholder="dropInPlaceholder"
      :size="size"
      @input="handleInput"
      @blur="handleBlur"
    />

    <Toggle
      v-else-if="type === 'toggle'"
      :value="internalValue"
      :disabled="isDisabled"
      :label="label"
      @change="($event) => (internalValue = $event, $emit('change', $event))"
    />

    <b-input-group
      v-else-if="type !== 'hidden'"
      :prepend="prepend"
      :append="append"
    >
      <b-form-input
        v-model="internalValue"
        :type="type"
        :state="state"
        :accept="accept"
        :tabindex="tabindex"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="isDisabled"
        :debounce="debounce"
        :size="size"
        trim
        @input="handleInput"
        @blur="handleBlur"
        @update="value => $emit('update', value)"
      />
    </b-input-group>
    <template slot="invalid-feedback">
      {{ invalidFeedback }}
    </template>
  </b-form-group>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { getInvalidCharacters } from '@/services/validation'
import Toggle from '@/components/common/Toggle'

// TODO: Consider https://github.com/charliekassel/vuejs-datepicker
// TODO: https://vue-multiselect.js.org

export default {
  name: 'FormField',
  components: { Toggle },
  mixins: [validationMixin],
  inject: ['registerFormField', 'unregisterFormField'],
  props: {
    value: {
      type: [String, Boolean, Number, Date, File, Array],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    selectDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Field is always disabled
     */
    permaDisabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    // Output formatting
    format: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      default: '',
    },
    validationRules: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // Makes it possible to disable validation
    novalidate: {
      type: Boolean,
      default: false,
    },
    // Used by `type === select & radio`
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    // Used by `type === text`
    append: {
      type: String,
      default: '',
    },
    prepend: {
      type: String,
      default: '',
    },
    // Used by `type === textarea`
    rows: {
      type: Number,
      default: 5,
    },
    // Used by `type === upload`
    browseLabel: {
      type: String,
      default: 'Browse',
    },
    dropInPlaceholder: {
      type: String,
      default: 'Drop file here...',
    },
    accept: {
      type: String,
      default: null,
    },
    // Info icon in label
    info: {
      type: String,
      default: null,
    },
    infoHeader: {
      type: String,
      default: null,
    },
    infoHTML: {
      type: Boolean,
      default: false,
    },

    /**
     * Custom css
     */
    className: {
      type: String,
      default: '',
    },
    groupClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: null,
    },
    debounce: {
      type: [Number, String],
      default: '0',
    },
    preselected: {
      type: [String, Boolean, Number, Date, File, Array],
      default: '',
    },
    canReset: {
      type: Boolean,
      default: false,
    },
    plannedDateAlert: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      originalValue: '',
      internalValue: '',
      blurred: false,
      datepickerLabels: {
        labelPrevDecade: 'Vorig decennium',
        labelPrevYear: 'Vorig jaar',
        labelPrevMonth: 'Vorige maand',
        labelCurrentMonth: 'Huidige maand',
        labelNextMonth: 'Volgende maand',
        labelNextYear: 'Volgend jaar',
        labelNextDecade: 'Volgend decennium',
        labelToday: 'Vandaag',
        labelSelected: 'Gekozen datum',
        labelNoDateSelected: 'Geen datum gekozen',
        labelCalendar: 'Kalender',
        labelNav: 'Kalender navigatie',
        labelHelp: 'Navigeer met de pijltjes door de kalender',
      },
      timepickerLabels: {
        labelHours: 'Uren',
        labelMinutes: 'Minuten',
        labelSeconds: 'Seconden',
        labelIncrement: 'Vermeerderen',
        labelDecrement: 'Verminderen',
        labelSelected: 'Gekozen tijd',
        labelNoTimeSelected: 'Geen tijd gekozen',
        labelCloseButton: 'Sluiten',
      },
    }
  },
  computed: {
    // groupClass () {
    //   return this.groupClass
    // },
    state() {
      if (this.novalidate) {
        return null
      }
      return this.$v.internalValue.$dirty ? !this.$v.internalValue.$error : null
    },
    isDisabled() {
      return this.disabled || this.permaDisabled
    },
    invalidFeedback() {
      let validator = this.$v.internalValue

      // Go over the validation rules, and return the
      // name of the first rule that is broken
      let match = Object.keys(this.validationRules)
        .find((rule) => {
          return ! validator[rule]
        })

      if (match === -1) {
        return '' // apparently no rules are broken?
      }

      let params = validator.$params

      switch (match) {
        case 'email':
          return 'Voer a.u.b. een geldig e-mail adres in'
        case 'required':
          return 'Dit is een vereist veld'
        case 'minLength':
          return 'Uw invoer moet minimaal ' + params.minLength.min +' karakters zijn.'
        case 'maxLength':
          return 'Uw invoer mag maximaal ' + params.maxLength.max + ' karakters zijn.'
        case 'isUniqueBuildingCode':
          return 'Dit nummer is reeds in gebruik binnen deze PID.'
        case 'multilineUnicodeString':
        case 'unicodeString':
          return `Uw invoer bevat karakters die omwille van veiligheid niet geaccepteerd worden: ${getInvalidCharacters(validator.$model)}`
      }

      return 'Controleer uw invoer a.u.b.'
    },
    hasInfo() {
      return this.info
    },
    isDateValueCancelled () {
      return this.internalValue && this.internalValue === 'Cancelled'
    },
    isDateCancellable () {
      return this.canReset && (this.internalValue && this.internalValue !== 'Cancelled') && !this.isDisabled
    },
  },
  watch: {
    /**
     * Observe changes to the value prop
     */
    value(newValue) {
      this.internalValue = this.type === 'number' && newValue ? parseInt(newValue, 10) : newValue
      this.internalValue = this.type === 'checkbox' && ! Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue
    },
  },
  created() {
    // On creation, set the original field value
    this.originalValue = this.value

    // On creation, set the internal field value
    this.internalValue = this.type === 'number' && this.value ? parseInt(this.value, 10) : this.value
    this.internalValue = this.type === 'checkbox' && ! Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue

    if (!this.value && this.preselected) {
      this.internalValue = this.preselected
    }

    if (this.type === 'toggle') { // Toggle value as empty string or no property yet => boolean
      this.internalValue = !!this.value
    }

    // If contained within a Form component, register the form field
    if (this.registerFormField) {
      this.registerFormField(this)
    }
  },
  beforeDestroy() {
    if (this.unregisterFormField) {
      this.unregisterFormField(this)
    }
  },
  /**
   * Vuelidate validation rules. Set through the validationRules prop
   */
  validations() {
    return (this.validationRules)
      ? { internalValue: this.validationRules }
      : {}
  },
  methods: {
    handleResetDatePicker () {
      this.$refs.datepicker.onResetButton()
      this.internalValue = 'Cancelled'
      this.$emit('input', this.internalValue)
    },
    getValue() {
      if (this.type === 'number') {
        return this.internalValue === '' ? 0 : parseInt(this.internalValue, 10)
      }
      if (this.format === 'float') {
        return this.internalValue === '' ? 0 : parseInt(this.internalValue, 10)
        // this.internalValue = this.internalValue.replace(',', '.') // Accept both , & .
        // return this.internalValue === '' ? 0 : parseFloat(this.internalValue)
      }
      if (this.type === 'datepicker' && this.internalValue === '') {
        return null
      }

      // Only return available values for radio and select types
      if (
        ['select', 'radio'].includes(this.type) &&
        ! this.options.map(option => option.value || option).includes(this.internalValue)
      ) {
        return null
      }
      return this.internalValue
    },
    getName() {
      return this.name
    },
    /**
     * Info modal
     */
    openInfo() {
      this.openModal({
        title: this.infoHeader || this.label,
        content: this.infoHTML
          ? this.$createElement('div', {
            domProps: {
              innerHTML: this.info,
            },
          })
          : this.info,
      })
    },
    openModal({ title, content }) {
      this.$bvModal.msgBoxOk(
        content,
        {
          title,
          okVariant: 'secondary',
          headerClass: 'pl-5',
          bodyClass: 'pr-5 pl-5 pt-4 pb-4',
        },
      )
    },

    // Start validation after initial blur
    handleBlur() {
      if (this.blurred === false) {
        this.validate()
      }
      this.blurred = true
      this.$emit('blur')
    },
    handleInput(value) {
      if (this.blurred || this.type === 'select') {
        !this.disabled && this.validate()
      }
      !this.disabled && this.$emit('input', value)
    },
    validate() {
      if (!this.novalidate) {
        this.$v.$touch()
      }
    },
    isValid() {
      // Not ideal, but otherwise form processing would cancel
      return this.novalidate ? true : !! this.state
    },
    resetValidation() {
      this.$v.$reset()
    },
    /**
     * Force reloading the field value
     */
    reloadFieldValue({ value }) {
      this.internalValue = this.type === 'number' && value ? parseInt(value, 10) : value
      this.internalValue = this.type === 'checkbox' && ! Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue
    },
    /**
     * Fields retain their attachement to the Form element when removed from the DOM
     */
    isDeleted() {
      return false
    },

    /**
     * Reset the field value to the original value
     */
    reset() {
      this.resetValidation()
      this.internalValue = this.type === 'number' && this.originalValue ? parseInt(this.originalValue, 10) : this.originalValue
      // TODO: emit input event (to let the parent component(s) know about the change)?
    },
  },
}
</script>

<style lang="scss">
// Due to Append / prepend with icon in button
// TODO: Better placing
.input-group input, .input-group select {
  min-height: 41px;
}

.input-group {
  .form-control {
    &:disabled {
      color: #6c757d;
    }
  }
}

.FormField {
  font-size: 16px;
  position: relative;

  select option:disabled {
    color: #495057 !important
  }
  input, select, .custom-select {
    min-height: 41px; // Due to Append / prepend with icon in button
    &:disabled {
      color: black;
      background-color: #e9ecef;
      // opacity: 1;
    }
  }


  label, legend {
    color: #7F8FA4;
    // text-transform: uppercase;
    padding-bottom: 0;
  }

  .info svg {
    max-height: 1rem;
    max-width: 1rem;
  }

  // .invalid-feedback {
  //   position: absolute;
  //   top: -1.75rem;
  //   text-align: right;
  // }


  &--choice, &--radio {
    height: 33px;

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: transparent;
      background-color: transparent;
    }
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-color: white;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 55.1 (78136) - https://sketchapp.com --%3E%3Ctitle%3ECheck Icon%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='FunderMaps' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Upload-D' transform='translate(-560.000000, -645.000000)' fill='%2328CC8B'%3E%3Cg id='Document-info' transform='translate(518.000000, 410.000000)'%3E%3Cg id='Selected' transform='translate(42.000000, 230.000000)'%3E%3Cpath d='M11.802,11.618 L7.693,15.798 C7.557,15.936 7.372,15.991 7.178,15.995 C7.16,15.998 7.142,16 7.124,16.001 C7.091,16 7.06,15.996 7.026,15.991 C6.841,15.981 6.664,15.927 6.533,15.794 L6.424,15.684 C6.422,15.681 6.419,15.68 6.416,15.678 C6.414,15.675 6.413,15.672 6.411,15.67 L4.208,13.437 C3.888,13.112 3.94,12.531 4.325,12.141 C4.71,11.75 5.282,11.697 5.603,12.023 L7.114,13.554 L10.407,10.204 C10.727,9.879 11.299,9.933 11.684,10.323 C12.069,10.714 12.122,11.293 11.802,11.618 M8,5 C3.582,5 0,8.582 0,13 C0,17.419 3.582,21 8,21 C12.418,21 16,17.419 16,13 C16,8.582 12.418,5 8,5' id='Check-Icon'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
    }
    .custom-radio.none .custom-control-input:checked ~ .custom-control-label::after {
      background-color: white;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 55.1 (78136) - https://sketchapp.com --%3E%3Ctitle%3ENone Icon%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='FunderMaps' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Upload-D' transform='translate(-940.000000, -673.000000)' fill='%23354052'%3E%3Cg id='Document-info' transform='translate(518.000000, 410.000000)'%3E%3Cg id='Selected' transform='translate(42.000000, 230.000000)'%3E%3Cpath d='M384,40.0393066 L392,40.0393066 C392.552285,40.0393066 393,40.4870219 393,41.0393066 C393,41.5915914 392.552285,42.0393066 392,42.0393066 L384,42.0393066 C383.447715,42.0393066 383,41.5915914 383,41.0393066 C383,40.4870219 383.447715,40.0393066 384,40.0393066 Z M388,33 C383.582,33 380,36.582 380,41 C380,45.419 383.582,49 388,49 C392.418,49 396,45.419 396,41 C396,36.582 392.418,33 388,33' id='None-Icon'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
    }
  }
  .datepicker {
    position: absolute;
    right: 1em;
    top: 0.8em;
    cursor: pointer;
  }
  .reset-date-alert {
    margin: 0.5em 0 0;
    position: relative;
    top: 100%;
    width: 100%;
  }
}
</style>

<template>
  <b-dropdown
    v-if="hasMultipleOptions"
    :variant="variant"
    :size="size"
    text="Nieuw realisatieproces"
    right
    split
    @click="handleStartUnspecifiedType"
  >
    <b-dropdown-item-button
      v-for="option in getOptions"
      :key="option.value"
      @click="handleStartWithType({ type: option })"
    >
      <div class="d-flex justify-content-between">
        <div>{{ option.text }}</div>
        <b-icon icon="plus" />
      </div>
    </b-dropdown-item-button>
  </b-dropdown>

  <b-button
    v-else
    :variant="variant"
    :size="size"
    @click="handleStartSingleType"
  >
    Nieuw realisatieproces<b-icon icon="plus" />
  </b-button>
</template>

<script>
//const uuid = require('uuid')

import { mapGetters } from 'vuex'

export default {
  name: 'StartProcessButton',
  props: {
    context: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('tenant', ['getCategoryOptionsByContext']),
    getOptions() {
      return this.getCategoryOptionsByContext({
        context: this.context,
      })
    },
    hasMultipleOptions() {
      return this.getOptions.length > 1
    },
  },
  methods: {
    handleStartSingleType() {
      let options = this.getOptions
      if (!options.length) {
        this.handleStartUnspecifiedType()
      } else {
        this.handleStartWithType({
          type: options[0],
        })
      }
    },
    handleStartUnspecifiedType() {
      let route = {
        name: process.env.VUE_APP_TENANT === 'rvb' ? 'new-private' : 'NewRealisation',
      }
      if (this.query) {
        route.query = this.query
      }
      this.$router.push(route)
    },
    handleStartWithType({ type }) {
      let route = {
        name: 'NewRealisationType',
        params: {
          type: type.value,
        },
      }
      if (this.query) {
        route.query = this.query
      }
      this.$router.push(route)
    },
  },
}
</script>

<style>

</style>

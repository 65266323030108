import { Client, fql } from 'fauna';

const state = {
  assets: null,
  selectedAsset: null,
}

const getters = {
  getAssets: (state) => state.assets,
  getSelectedAsset: (state) => state.selectedAsset,
}
const actions = {
  setSelectedAsset ({ commit }, { asset }) {
    commit('setSelectedAsset', { asset })
  },

  addAssetProcess ({ commit }, { asset }) {
    commit('addAssetProcess', { asset })
  },

  replaceAsset ({ commit }, { asset }) {
    commit('replaceAsset', { asset })
  },

  setAssets ({ commit }, { assets }) {
    commit('setAssets', { assets })
  },

  deleteAsset ({ commit }, { locationId }) {
    commit('deleteAsset', { locationId })
  },

  deleteAssetProcess ({ commit }, { record }) {
    commit('deleteAssetProcess', { record })
  },
  async getNextLocationId () {
    const client = new Client({
      secret: process.env.VUE_APP_FAUNA_KEY,
    });

    const  year = new Date(Date.now()).getFullYear()
    const nextLocationId = fql`
      Assets.byTenant(${process.env.VUE_APP_TENANT})
        .where(.location?.year == ${year})
        .order(desc(.location.counter))
        .first() {
          location
        }
    `
    const response = await client.query(nextLocationId)
    if (response.data.location) {
      const location = response.data.location
      location.counter++
      location.full = String(+location.full + 1)

      return location
    }
    return 'No locationId found.'
  }
}
const mutations = {
  setSelectedAsset (state, { asset }) {
    state.selectedAsset = asset
  },

  setAssets (state, { assets }) {
    state.assets = assets
  },

  addAssetProcess (state, { asset }) {
    state.assets.splice(0, 0, asset)
  },

  replaceAsset (state, { asset }) {
    const assetIndex = state.assets.findIndex(current => current.data.locationId === asset.locationId)
    if (assetIndex !== -1) {
      state.assets[assetIndex].data = asset
    }
  },

  deleteAsset (state, { locationId }) {
    const assetIndex = state.assets.findIndex(current => current.data.locationId === locationId)
    if (assetIndex !== -1) {
      state.assets.splice(assetIndex, 1)
    }
  },

  deleteAssetProcess (state, { record }) {
    const assetIndex = state.assets.findIndex(current => current.data.locationId === record.locationId?.full)
    if (assetIndex !== -1) {
      const processIndex = state.assets[assetIndex].data.processes.findIndex(process => process.processUuid === record.uuid)
      if (processIndex !== -1) {
        state.assets[assetIndex].data.processes.splice(processIndex, 1)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

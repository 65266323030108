import Location from './ProcessLocation';
import { isObject } from '@/services/validation';
import { getMunicipalityNameByCode } from '@/services/municipalities';

const leadingZero = num => `0${num}`.slice(-2);

// let currentYear = (new Date()).getFullYear()
const formatTime = date =>
  [date.getHours(), date.getMinutes()] // , date.getSeconds()
    .map(leadingZero)
    .join(':');

class Process {
  constructor({ ref, data, newProcess }) {
    data = this.commonStructure({ ref, data, newProcess });
    data.tenant = process.env.VUE_APP_TENANT;

    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }

  /******************************************************************************
   * Shared methods
   */
  getRef() {
    return this.ref || null;
  }
  isUnsavedRecord() {
    return this.ref === null;
  }
  getModelName() {
    return 'Process';
  }
  setTags({ Tags }) {
    this.Tags = Tags;
  }
  hasTags() {
    return !!(Array.isArray(this.Tags) && this.Tags.length !== 0);
  }
  getTags() {
    return Array.isArray(this.Tags) ? this.Tags : [];
  }
  getActiveProcessTypes() {
    return this.$store.getters['tenant/getActiveProcessTypes'];
  }
  getTagLabels() {
    const tenantTags = this.$store.getters['tenant/getTags'];
    const tags = this.getTags() || [];
    const tagLabels = tags.map(tag => tenantTags.find(t => t.uuid === tag)?.label);

    return tagLabels;
  }
  getCaseRef() {
    return this.case_ref;
  }
  getLocation() {
    return this.values.Location;
  }
  hasDocOfType({ doctype }) {
    return Array.isArray(this.Docs[doctype]) && this.Docs[doctype].length !== 0;
  }
  getNextDocTypeId({ doctype }) {
    let ids = (this.Docs[doctype] || []).map(doc => {
      return isObject(doc) ? doc.id : doc;
    });
    return ids.length ? Math.max(...ids) + 1 : 1;
  }
  nextLocationVersion() {
    // console.log(this.history)
    return Math.max(...this.history.map(location => location.version)) + 1;
  }
  getCategory = function () {
    return this.category || null;
  };
  /******************************************************************************
   * Process Type
   */
  // setProcessType({ processType }) {
  //   this.processType = processType;
  // }
  // getProcessType() {
  //   return this.processType || null;
  // }
  // hasProcessType() {
  //   return this.getProcessType() !== null;
  // }
  // getProcessTypeLabel() {
  //   return (
  //     this.$store.getters['tenant/getSelectOptionLabelByValue']({
  //       name: 'processtypes',
  //       value: this.processType
  //     }) || ''
  //   );
  // }
  generateSearchData = ({ model, data }) => {
    let municipalityName = '';
    if (data.Municipality) {
      let municipalityOption = this.$store.getters['tenant/getMunicipalityOptions'].find(
        option => option.value === data.Municipality
      );
      if (municipalityOption) {
        municipalityName = municipalityOption.text;
      }
    }

    return {
      City: model.CurrentLocation ? model.CurrentLocation.Location.CityName : '',
      Municipality: municipalityName
    };
  };
  /******************************************************************************
   * Events
   */
  calculateStepDuractions({ Events }) {
    return Events.reduce((durations, Event) => {
      //if (debug) console.log(durations)
      //if (debug) console.log(Event)
      if (Event.type === 'step-start') {
        //if (debug) console.log("start")
        let uuid = Event?.meta?.uuid || null;
        if (uuid !== null) {
          let current = durations[uuid] || false;

          //if (debug) console.log(current)
          // Create a new entry if there is no current data,
          // or if the start is after the currently registered end
          if (!current || Event.ts > current.end) {
            //if (debug) console.log("no current / ts greater than to current end")
            durations[uuid] = {
              start: Math.max(durations[uuid]?.start || 0, Event.ts),
              end: 0,
              finished: false
            };
          } else {
            //if (debug) console.log("current and ts equal or lower than current end")
            durations[uuid].start = Math.max(durations[uuid].start, Event.ts);
          }
        }
      } else if (Event.type === 'step-end') {
        //if (debug) console.log('end')
        let uuid = Event?.meta?.uuid || null;
        if (uuid !== null) {
          let current = durations[uuid] || false;

          //if (debug) console.log(current)
          // If there already an entry...
          if (current) {
            // Only accept end dates after or equal to the registred start date
            if (current.start <= Event.ts) {
              //if (debug) console.log("start is equal to or after end")
              // Always register the latest end date
              durations[uuid].end = Math.max(durations[uuid].end, Event.ts);
              durations[uuid].finished = true;
            } else {
              //if (debug) console.log("ignore")
            }
          } else {
            //if (debug) console.log("set or replace")
            durations[uuid] = {
              start: 0,
              end: Event.ts,
              finished: true
            };
          }
        }
      }

      //if (debug) console.log(durations)
      return durations;
    }, {});
  }
  getStepDuractionByUuid({ uuid }) {
    return (
      this.processDurationByStep[uuid] || {
        start: 0,
        end: 0,
        finished: false
      }
    );
  }
  getCurrentStepDuraction() {
    const currentFlowStatus = this.getCurrentFlowStatus();
    if (!currentFlowStatus || !currentFlowStatus.uuid) {
      return 0;
    }
    return this.getStepDuractionByUuid({
      uuid: currentFlowStatus.uuid
    });
  }
  generateSearchData() {
    return {};
  }
  generateAddressData({ model }) {
    const CurrentLocation = model.CurrentLocation;
    const location = CurrentLocation.Location;
    const object = CurrentLocation.Object;
    const municipality = getMunicipalityNameByCode({ code: CurrentLocation.Municipality });
    const municipalityName = municipality && `(${municipality})`;
    const HouseNumberSuffix = location.HouseNumberSuffix ? ` ${location.HouseNumberSuffix}` : '';
    const evNet = model.CurrentLocation.FinUnit === 'spv-evnet-nl' ? '(Intern)' : '';

    if (location?.StreetName) {
      return `${location.StreetName} ${(location.HouseNumber + HouseNumberSuffix).trim()}, ${location.CityName} ${municipalityName} ${evNet}`.replace(
        '  ',
        ' '
      );
    }
    if (object?.Address){
      const location = object.Address;
      return `${location.StreetName} ${(location.HouseNumber + HouseNumberSuffix).trim()}, ${location.CityName}`
    }
    return '';
  }
  generateMunicipalityData({ data }) {
    return data.MunicipalityCode || data.Municipality || null;
  }
  getFlowUuid() {
    return this.flow && this.flow.uuid ? this.flow.uuid : false;
  }
  statusByFlow({ flow }) {
    if (!Array.isArray(flow.state)) return;
    let steps =
      this.$store.getters['tenant/getWorkflowSpecByName']({
        name: 'realisation'
      })?.steps || [];

    // most recently started step that has not been completed
    const state = Array.isArray(flow.state) ? flow.state : [];
    let uuid = state
      .filter(step => !step.completed_at)
      .sort((a, b) => a.started_at - b.started_at)
      .map(step => step.uuid)
      .shift();

    // There was no uncompleted step. Perhaps the process was completed.
    if (!uuid) {
      // Something could have gone horribly wrong with the flow state
      if (flow.state.length === 0) {
        return {
          step: 1,
          label: 'Onbekend'
        };
      }

      // Was the final step completed?
      let final = steps.find(step => step.final === true);
      if (final && state.find(step => step.uuid === final.uuid)) {
        return {
          step: final.step || final.short,
          uuid: final.uuid,
          version: final.version,
          label: 'Afgerond'
        };
      }

      // If not, return the details of the step that was completed last
      uuid =
        state
          .filter(step => step.completed_at)
          .sort((a, b) => {
            // Note the assumption that the later step in the flow follows after the former
            return b.completed_at === a.completed_at ? -1 : b.completed_at - a.completed_at;
          })[0].uuid || null;
    }

    let step = steps.find(step => step.uuid === uuid);
    if (!step) {
      // Perhaps we got a case of mixed flows?
      return {
        step: 1,
        label: 'Onbekend'
      };
    }

    return {
      step: step.step || step.short,
      uuid: step.uuid,
      version: step.version,
      label: step.label
    };
  }
  getCurrentFlowStatus() {
    return this.statusByFlow({
      flow: this.flow
    });
  }
  orderAvailableFlowSteps() {
    return this.flow.state.filter(state => state.started_at).sort((a, b) => a.started_at - b.started_at);
  }
  nextAvailableFlowStep({ currentUuid }) {
    let order = this.orderAvailableFlowSteps();
    // console.log("order", order)
    let index = order.findIndex(state => state.uuid === currentUuid);
    return order[index + 1] ? order[index + 1] : order[0];
  }
  previousAvailableFlowStep({ currentUuid }) {
    let order = this.orderAvailableFlowSteps();
    let index = order.findIndex(state => state.uuid === currentUuid);
    return order[index - 1] ? order[index - 1] : order[0];
  }
  commonStructure({ ref, data, newProcess }) {
    let model = {};

    // console.log("new Location", ref, data)
    let updated_at = 'Onbekend';
    let updated_at_short = '';
    if (data.updated_at) {
      let recordDate = new Date(data.updated_at);
      // es-CL = DD-MM-YYYY
      updated_at = `${recordDate.toLocaleDateString('es-CL')} ${formatTime(recordDate)}`;

      // old data
      updated_at_short = {
        date: recordDate
          .toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' })
          .replace('. ', ' \''),
        time: formatTime(recordDate)
      };
      // if (currentYear > recordDate.getFullYear()) {
      //   updated_at_short = recordDate.toLocaleDateString("nl-NL", { day: 'numeric', month: 'short', year: 'numeric' })
      // } else {
      // }
    }
    // console.log(data.Locations)
    // The first Location is the current Location
    const history = data.Locations ? data.Locations.map(data => new Location({ data })) : [];

    let [CurrentLocation] = data.Locations; // || {}
    const realisationData = data.realisationProcessData;

    if (realisationData && Object.keys(realisationData).length) {
      realisationData.Locations[0].ChargerIds = [] // Reset realisation chargerIds
    }

    CurrentLocation = new Location({ data: CurrentLocation, processType: data.processType, realisationData });

    try {
      let status = !newProcess && data.status ? data.status : null;

      // Fallback...
      if (status === null) {
        let steps =
          this.$store.getters['tenant/getWorkflowSpecByName']({
            name: 'realisation'
          })?.steps || [];

        status = {
          step: steps[0].short || steps[0].step,
          uuid: steps[0].uuid,
          version: steps[0].version,
          label: steps[0].label,
          completed: false,
          cancelled: false,
          onhold: false,
          vkb: false
        };
      }

      // Go by process flow
      if (data.flow) {
        // console.log("flow available... !", data.flow, ref)
        status = Object.assign(status, this.statusByFlow({ flow: data.flow }));
        // console.log(status)
      }

      let statusMetaDefaults = {
        onhold: {
          reason: '',
          label: '',
          comment: ''
        },
        cancelled: {
          reason: '',
          label: '',
          comment: ''
        },
        onholdReason: '',
        onholdLabel: '',
        onholdComment: '',
        cancelledReason: '',
        cancelledLabel: '',
        cancelledComment: ''
      };
      let statusMeta = statusMetaDefaults;

      if (data.statusMeta) {
        statusMeta = Object.assign(statusMetaDefaults, data.statusMeta);
      }

      if (CurrentLocation) {
        CurrentLocation.Municipality = data.MunicipalityCode || data.Municipality || null;
        CurrentLocation.MunicipalityCode = CurrentLocation.Municipality;
      }

      // if (newProcess) {
      //   Object.keys(data.Docs).map(doc => data.Docs[doc] = [])
      // }

      model = {
        ref,
        uuid: data.uuid,
        processType: data.processType || null,

        raw: JSON.parse(JSON.stringify(data)),
        realisationCategory: data.realisationCategory,
        case_ref: data.case?.full || '',
        realisationProcessData: data.realisationProcessData,

        status,
        statusMeta,
        locationId: data.locationId || null,
        isAsset: data.isAsset || null,

        /**
         * Step 1 only for now
         */
        values: {
          Location: CurrentLocation
        },

        CurrentLocation,

        history,

        Docs: data.Docs
          ? {
              LocationProposition: data.Docs.LocationProposition || [],
              StreetCabinet: data.Docs.StreetCabinet || [],
              ParkingSpots: data.Docs.ParkingSpots || [],
              Underground: data.Docs.Underground || [],
              Broker: data.Docs.Broker || [],
              GridOperator: data.Docs.GridOperator || [],
              CPOAdditional: data.Docs.CPOAdditional || [],
              TrafficDecision: data.Docs.TrafficDecision || [],
              DeliveryDocumentation: data.Docs.DeliveryDocumentation || [],
              SideView: data.Docs.SideView || [],
              TopView: data.Docs.TopView || []
            }
          : {
              ParkingSpots: [],
              Underground: [],
              Broker: [],
              GridOperator: [],
              CPOAdditional: [],
              TrafficDecision: [],
              DeliveryDocumentation: [],
              SideView: [],
              TopView: [],
              StreetCabinet: []
            },

        /**
         * Connected requests
         */
        requestUuids: data.requestUuids || [],
        relationalChanges: {
          removed: [],
          added: []
        },

        /**
         * Tags
         */
        Tags: data.Tags || [],

        /**
         * TODO: Remarks etc.
         */
        Events: data.Events || [],

        /**
         * Calculated based on events
         */
        processDurationByStep: {},

        /**
         * Comments
         */
        comments: data.comments || [],

        /**
         * The process flow information
         */
        flow: newProcess ? null : data.flow || null,

        updated_at,
        updated_at_short,

        //private
        Application: CurrentLocation?.Application || null,
        Object: CurrentLocation?.Object || null,
        ParkingAndChargePoints: CurrentLocation?.ParkingAndChargePoints || null,
        Energy: CurrentLocation?.Energy || null,
        Progress: CurrentLocation?.Progress || null,
        Party: CurrentLocation?.Party || null,
        Notes: CurrentLocation?.Notes || '',
        BuildingOptions: CurrentLocation?.BuildingOptions || null,
        BuildingAdjustments: CurrentLocation?.BuildingAdjustments || null,
        Meta: CurrentLocation?.Meta || null,
      };

      model.processDurationByStep = this.calculateStepDuractions({ Events: model.Events, case_ref: model.case_ref });

      model.Municipality = this.generateMunicipalityData({ model, data });
      model.MunicipalityCode = model.Municipality;
      model.pidNumber = CurrentLocation.Application?.PIDNumber || '';
      model.pidUuid = CurrentLocation.Application?.PidUuid || '';
      model.address = this.generateAddressData({ model, data });
      model.region = CurrentLocation.Object?.Address?.RVBRegion || '';
      model.customer = CurrentLocation.Object?.Address?.Customer || '';
      model.buildingCode = CurrentLocation.Object?.Address?.BuildingCode || '';
      model.projectLeader = CurrentLocation.Object?.Address?.RegionalProjectLeader || '';
      model.objectManager = CurrentLocation.Object?.ObjectManager || '';
      model.assetManager = CurrentLocation.Object?.AssetManager || '';
      model.numberOfExistingChargePoints = CurrentLocation.ParkingAndChargePoints?.NumberOfExistingChargePoints || 0;
      model.chargePointNeedsGovernmentOffices = CurrentLocation.ParkingAndChargePoints?.ChargePointNeedsGovernmentOffices || 0;
      model.chargePointNeedsNewProgram = CurrentLocation.ParkingAndChargePoints?.ChargePointNeedsNewProgram || 0;
      model.extraNeedChargePoints = CurrentLocation.ParkingAndChargePoints?.ExtraNeedChargePoints || 0;
      model.totalChargePointsStillToBePlaced = CurrentLocation.ParkingAndChargePoints?.TotalChargePointsStillToBePlaced || 0;
      model.chargePointsRealized = CurrentLocation.ParkingAndChargePoints?.ChargePointsRealized || 0;
      model.powerOption = CurrentLocation.BuildingOptions?.PowerOption || '';
      model.fuseBoxOption = CurrentLocation.BuildingOptions?.FuseBoxOption || '';
      model.allPartiesSignedDate = CurrentLocation.Progress?.AllPartiesSignedDate || '';
      model.remarks = CurrentLocation.Progress?.Remarks || '';
      model.objectType = CurrentLocation.Object?.Address?.ObjectType || '';
      model.search = this.generateSearchData({ model, data });
    } catch (e) {
      console.log(e);
      console.log('Failed to construct data model');
    }
    //  console.log('MODEL', model)
    return model;
  }
}

export default Process;

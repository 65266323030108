
import { formatPostalCode } from '@/helpers/string'

const component = require(`@/models/${process.env.VUE_APP_TENANT}/Location.js`)
const Location = component.default || function() {}

Location.prototype.hasCoordinates = function() {
  return (this.Location.Latitude && this.Location.Longitude) || this.Location.chargingpointUuid
}

Location.prototype.getCoordinates = function() {
  try {
    if (this.Location.chargingpointUuid) {
      let location = this.$store.getters['chargingpoints/getChargingPointByUUID']({
        uuid: this.Location.chargingpointUuid,
      })
      return location.data.address.coordinates
    }

    return {
      lat: this.Location.Latitude,
      lng: this.Location.Longitude,
    }
  } catch (e) {
    return false
  }
}

if (!Location.prototype.getAddress) {
  Location.prototype.getAddress = function() {
    const location = this.Location
    const number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
    return (`${location.StreetName || ''} ${number}, ${formatPostalCode(location.PostalCode || '')} ${location.CityName || ''}`).trim().trim(',')
  }
}

if (!Location.prototype.getStreetAndCity) {
  Location.prototype.getStreetAndCity = function() {
    try {
      let location = this.Location
      let number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
      return (`${location.StreetName || ''} ${number}, ${location.CityName || ''}`).trim().trim(',')
    } catch (e) {
      return false
    }
  }
}

Location.prototype.hasChargingpointLocation = function() {
  return this.Location.chargingpointUuid
}

Location.prototype.hasDocOfType = function({ doctype }) {
  return Array.isArray(this.Docs[doctype]) && this.Docs[doctype].length !== 0
}

Location.prototype.getDocTypeCount = function({ doctype }) {
  return this.hasDocOfType({ doctype }) ? this.Docs[doctype].length : 0
}

Location.prototype.LooksAndQuacksLikeCopy = ({ data }) => {
  return data instanceof Location
}


/******************************************************************************
 * Common structure
 */
 if (! Location.prototype.commonStructure) {
  Location.prototype.commonStructure = function({ data }) {

    /**
     * Address & coordinates
     */
    data.Location = data.Location || {}

    const countryMap = {
      'Netherlands': 'Nederland',
      'Belgium': 'België'
    }

//console.log(data)
    /**
     * Data model mapping
     */
    let model = {
      version: data.version || 1,

      raw: JSON.parse(JSON.stringify(data)),

      /**
       * Address information & coordinates
       */
      Location: {
        chargingpointUuid: data.Location.chargingpointUuid || null,
        chargingpointStatus: data.Location.chargingpointStatus || null,
        StreetName: data.Location.StreetName || '',
        HouseNumber: data.Location.HouseNumber || null,
        HouseNumberSuffix: data.Location.HouseNumberSuffix || '',
        PostalCode: data.Location.PostalCode || '',
        CityName: data.Location.CityName || '',
        Province: data.Location.Province || '',
        Country: data.Location.Country ? (countryMap[data.Location.Country] || data.Location.Country) : '',
        Latitude: data.Location.Latitude || '',
        Longitude: data.Location.Longitude || '',
        AdditionalCoordinates: data.Location.AdditionalCoordinates || [],
      },

      /****************************************************************************
       * Basics
       */
      Remarks: data.Remarks || '',
      Customer: data.Customer || '',
      ChargePointOperator: data.ChargePointOperator || '',
      Contractor: data.Contractor || '', // This is a UUID


      /****************************************************************************
       * Documents
       */
      Docs: data.Docs ? {
        LocationProposition: data.Docs.LocationProposition || [],
        StreetCabinet: data.Docs.StreetCabinet || [],
        ParkingSpots: data.Docs.ParkingSpots || [],
        Underground: data.Docs.Underground || [],
        Broker: data.Docs.Broker || [],
        GridOperator: data.Docs.GridOperator || [],
        CPOAdditional: data.Docs.CPOAdditional || [],
        TrafficDecision: data.Docs.TrafficDecision || [],
        DeliveryDocumentation: data.Docs.DeliveryDocumentation || [],
        SideView: data.Docs.SideView || [],
        TopView: data.Docs.TopView || [],
      } : {
        ParkingSpots: [],
        Underground: [],
        Broker: [],
        GridOperator: [],
        CPOAdditional: [],
        TrafficDecision: [],
        DeliveryDocumentation: [],
        SideView: [],
        TopView: [],
        StreetCabinet: []
      },

      /**
       * Mail log
       */
      MailLog: data.maillog || {},
    }


    /****************************************************************************
     * Parking spots
     */
    let ParkingSpotsType = 'station'
    if (data.ParkingSpots) {
      ParkingSpotsType = ['station', 'hub'].includes(data.ParkingSpots.Type)
        ? data.ParkingSpots.Type
        : 'station'
    }
    model.ParkingSpots = {
      Type: ParkingSpotsType, // or hub
      NumberOfChargingStations: data.ParkingSpots
        ? data.ParkingSpots.NumberOfChargingStations
        : 1, // 3+ for hub
      NumberOfParkingSpots: data.ParkingSpots
        ? data.ParkingSpots.NumberOfParkingSpots
        : 1,
      Identifiers: data.ParkingSpots && data.ParkingSpots.Identifiers
        ? data.ParkingSpots.Identifiers.map(value => {
          return {
            Identifier: value,
          }
        })
        : [],
    }


    /****************************************************************************
     * Broker Approval
     */
    model.BrokerApproval = data.BrokerApproval || {
      IsBrokerAccepted: '',
      BrokerName: '',
      BrokerComments: '',
      BrokerApprovalRequestedDate: '',
      BrokerApprovalReceivedDate: '',
    }
    // Only accept valid options
    if (
      data.BrokerApproval
      && ['yes', 'no', 'blanco', 'negative-advice', ''].includes(
        data.BrokerApproval.IsBrokerAccepted,
      )
    ) {
      model.BrokerApproval.IsBrokerAccepted = data.BrokerApproval.IsBrokerAccepted
    }


    /****************************************************************************
     * Grid Operator Approval
     */
    model.GridOperatorApproval = data.GridOperatorApproval || {
      IsGridOperatorAccepted: '',
      GridOperatorName: '',
      GridOperatorComments: '',
      GridOperatorApprovalRequestedDate: '',
      GridOperatorApprovalReceivedDate: '',
    }
    // Only accept valid options
    if (
      data.GridOperatorApproval
      && ['yes', 'no'].includes(data.GridOperatorApproval.IsGridOperatorAccepted)
    ) {
      model.GridOperatorApproval.IsGridOperatorAccepted = data.GridOperatorApproval.IsGridOperatorAccepted
    }


    /****************************************************************************
     * CPO Advice
     */
    model.CPOAdvice = data.CPOAdvice || {
      CPOAdvice: null,
      CPOComments: '',
      SumAdditionalCost: null,
      Decision: null,
      AdditionalCostDescription: '',
      ChargingStationVersion: '',
      PowerType: ''
    }


    /****************************************************************************
     * Order Confirmation
     */
    // model.OrderConfirmation = data.OrderConfirmation ? {
    //   IsOrderConfirmed: [data.OrderConfirmation.IsOrderConfirmed],
    //   IsAdditionalWorkConfirmed: [data.OrderConfirmation.IsAdditionalWorkConfirmed],
    //   Approver: data.OrderConfirmation.Approver,
    // } : {
    //   IsOrderConfirmed: [],
    //   IsAdditionalWorkConfirmed: [],
    //   Approver: '',
    // }

    /****************************************************************************
     * Various data from last steps (mostly dates)
     */
    model.TrafficDecision = data.TrafficDecision || {
      PublicationDate: '',
      DefinitiveDate: '',
    }

    model.GridConnection = data.GridConnection || {
      RequestDate: '',
      GridOperatorNumber: '',
      ConnectionValue: '',
    }

    model.PlannedRealisation = data.PlannedRealisation || {
      PlannedDate: '',
    }
    model.LocationPreparation = data.LocationPreparation || {
      RequiresLocationPreparation: [],
      OtherReason: '',
    }

    model.DateOfCommissioning = data.DateOfCommissioning || {
      DateOfCommissioning: '',
      DateOfEnergyConnection: '',
      EAN: '',
    }

    model.FinalDelivery = data.FinalDelivery || {
      LocationReadyDate: '',
      ChargerReadyDate: '',
      BatchCode: '',
    }
    model.ChargerIds = data.ChargerIds ? data.ChargerIds.map((item, index) => {
      const ChargerType = item.ChargerType || (index === 0 ? 'principal' : 'satellite')
      return {
        ChargerId: item.ChargerId || item,
        ChargerType
      }
    }) : []

    model.raw.ChargerIds = model.ChargerIds // For restore at validation
    return model
  }
}

Location.prototype.makePlainCopy = function({ data }) {
  data = JSON.parse(JSON.stringify(data)) // data.raw

  // Only add raw if not yet set on original
  if (! data.raw) {
    data.raw = JSON.parse(JSON.stringify(data))
  }

  return data
}

Location.prototype.getTrafficDecisionDetails = function() {
  return Object.assign({
    PublicationDate: false,
    DefinitiveDate: false,
    ConfirmedDate: false,
  }, this.TrafficDecision)
}

export default Location

<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="hasAccess">
    <h3
      id="table_5"
      class="mt-3"
    >
      Aanvragen per klant
    </h3>
    <div class="d-flex gap-1">
      <div
        v-for="(filter, key) in statusFilters.filters"
        :key="key"
        class="d-flex align-items-center"
      >
        <Button
          type="button"
          :class="selectedStatusFilter === key ? 'p-button-primary p-button-sm' : statusFilters.class"
          :label="filter.label"
          :badge="String(filter.count)"
          :badge-class="`p-badge-${selectedStatusFilter === key ? 'primary' : filter.badge || 'secondary'}`"
          @click="handleFilter({ filter: key })"
        />
      </div>
      <span class="p-input-icon-left p-input-icon-right mr-auto w-25">
        <i class="pi pi-search no-events" />
        <InputText
          :value="filters.global.value"
          placeholder="Zoeken"
          class="w-100"
          style="height: 37px"
          @input="handleInput"
        />
        <i
          v-if="filters.global.value"
          class="pi pi-times pointer"
          @click="filters.global.value = ''"
        />
      </span>
    </div>
    <div class="card mt-3">
      <data-table
        v-if="processes.length"
        data-key="uuid.value"
        :value="processes"
        paginator
        class="processes-table p-datatable-sm"
        :filters.sync="filters"
        :rows="10"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows-per-page-options="[10, 25,50, 100]"
        current-page-report-template="{first} tot {last} van {totalRecords}"
        sort-field="pidNumber.value"
        :sort-order="1"
        striped-rows
      >
        <Column>
          <template #header>
            <div class="flex-column">
              <div class="mb-2">
                PID
              </div>
              <div class="mb-2">
                Regio
              </div>
              <div>Straat</div>
            </div>
          </template>
          <template #body="{ data }">
            <div
              class="mb-2"
              v-html="highlightSearchTerm(data.pidNumber.value)"
            />
            <div
              class="mb-2"
              v-html="highlightSearchTerm(data.region.value)"
            />
            <div v-html="highlightSearchTerm(data.address.value)" />
          </template>
        </Column>
        <Column :styles="{ 'border-left': '1px solid #ccc' }">
          <template #header>
            <div class="flex-column">
              <div class="mb-2">
                RPL
              </div>
              <div class="mb-2">
                Object Manager
              </div>
              <div>Asset Manager</div>
            </div>
          </template>
          <template #body="{ data }">
            <div
              class="mb-2"
              v-html="highlightSearchTerm(data.projectLeader)"
            />
            <div
              class="mb-2"
              v-html="highlightSearchTerm(data.objectManager.value)"
            />
            <div v-html="highlightSearchTerm(data.assetManager.value)" />
          </template>
        </Column>
        <Column
          header-class="center"
          :styles="{ 'text-align': 'center', 'border-left': '1px solid #ccc' }"
        >
          <template #header>
            <div class="flex-column">
              <div class="mb-2">
                Bestaande laadpunten
              </div>
              <div class="mb-2">
                4% behoefte
              </div>
              <div class="mb-2">
                Behoefte Programma 2.0
              </div>
              <div class="mb-2">
                Extra behoefte
              </div>
              <div class="font-weight-bold">Aantal laadpunten gerealiseerd</div>
            </div>
          </template>
          <template #body="{ data }">
            <div class="mb-2">
              {{ data.numberOfExistingChargePoints.value }}
            </div>
            <div class="mb-2">
              {{ data.chargePointNeedsGovernmentOffices.value }}
            </div>
            <div class="mb-2">
              {{ data.chargePointNeedsNewProgram.value }}
            </div>
            <div class="mb-2">
              {{ data.extraNeedChargePoints.value }}
            </div>
            <div class="font-weight-bold">
              {{ data.chargePointsRealized.value }}
            </div>
          </template>
        </Column>
        <Column :styles="{ 'width': '350px', 'border-left': '1px solid #ccc' }">
          <template #header>
            <div class="flex-column">
              <div class="mb-2">
                Datum van PVO ondertekening
              </div>
              <div class="mb-2">
                Conclusie over de capaciteit voor de laadpunten
              </div>
              <div>Opties voor de hoofdverdeelkast</div>
            </div>
          </template>
          <template #body="{ data }">
            <div
              class="mb-2"
              v-html="highlightSearchTerm(data.allPartiesSignedDate.value)"
            />
            <div class="mb-2">
              <span v-html="highlightSearchTerm(data.powerOption)" />
            </div>
            <div> <span v-html="highlightSearchTerm(data.fuseBoxOption)" /></div>
          </template>
        </Column>
        <Column
          field="comments.value"
          header="Opmerking"
          :styles="{ 'width':'300px','border-left': '1px solid #ccc' }"
        >
          <template #body="{ data }">
            <div v-if="data.comments.value">
              <div><strong v-html="highlightSearchTerm(data.comments.value.UserName)" /></div>
              <div
                class="xsmall"
                v-html="highlightSearchTerm(data.commentsTime)"
              />
              <div
                class="small border-top"
                v-html="highlightSearchTerm(data.comments.value.Message)"
              />
            </div>
          </template>
        </Column>
        <Column
          v-for="column in searchableColumns"
          :key="column"
          :field="column"
          hidden
        />
      </data-table>
    </div>
  </div>
  <div v-else>
    U heeft geen toegang tot klantrapportages
  </div>
</template>

<script>
import { RegionalProjectLeaders } from '@/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api/'

export default {
  name: 'RVBCustomers',
  components: {
    DataTable,
    Column,
    Button,
    InputText
  },

  data() {
    return {
      filters: {
        global: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      processes: [],
      selectedStatusFilter: 'all',
      statusFilters: {
        filters: {
          all: { label: 'Alles', count: 0 },
          active: { label: 'Actief', count: 0 },
          onhold: { label: 'On hold', count: 0 },
          completed: { label: 'Afgerond', count: 0 },
          cancelled: { label: 'Geannuleerd', count: 0 }
        },
        class: 'p-button-sm p-button-outlined p-button-secondary',
      },
      searchableColumns: ['pidNumber.value', 'address.value', 'region.value', 'projectLeader', 'objectManager.value', 'assetManager.value', 'allPartiesSignedDate.value', 'powerOption', 'fuseBoxOption', 'comments.value.UserName', 'commentsTime', 'comments.value.Message'],
    }
  },

  computed: {
    ...mapGetters('processes', ['getProcessTableRecords']),
    ...mapGetters('user', [
      'securityCheck'
    ]),
    ...mapGetters('tenant', [
      'getLabelsByPidUuid'
    ]),
    hasAccess() {
      return this.securityCheck({ attribute: 'reporting' })
    }
  },

  watch: {
    getProcessTableRecords: {
      immediate: true,
      handler (value) {
        if (value.length) {
          this.processes = value.map(process => ({
            ...process,
            projectLeader: this.RPLAuthToName(process.projectLeader.value),
            powerOption: this.PowerOptionToLabel(process.powerOption.value),
            fuseBoxOption: this.FuseBoxOptionToLabel(process.fuseBoxOption.value),
            commentsTime: process.comments?.value?.Time ? this.setDate(process.comments.value.Time) : '',
           }))
          this.statusFilters.filters.all.count = value.length
          this.setStatusFiltersCounts(value)
        }
      },
    },
  },

  methods: {
    highlightSearchTerm (data) {
      let search = this.filters.global.value
      if (search) {
        const match = (search && data) ? String(data).match(new RegExp(search, 'i')) : null

        if (match) {
            search = String(data).replace(match[0],
              `<span class="hl-search">${match[0]}</span>`,
            )
          return search
        } else {
          search = ''
        }
      }

      return data
		},
    setStatusFiltersCounts (records) {
      const data = records || this.processes
      Object.values(this.statusFilters.filters).forEach(filter => filter.count = 0)

      data.map(record => {
        const statuses = record.status.value
        Object.keys(statuses).forEach(status => statuses[status] ? this.statusFilters.filters[status].count ++ : null)
      })
    },

    handleInput (event) {
      if (event.length < 3 && event) return
      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        this.filters.global.value = event.trim()
      }, 300)
    },

    handleFilter ({ filter }) {
      if (this.selectedStatusFilter === filter) return
      this.selectedStatusFilter = filter
      this.processes = this.getProcessTableRecords.map(process => ({
            ...process,
            projectLeader: this.RPLAuthToName(process.projectLeader.value),
            powerOption: this.PowerOptionToLabel(process.powerOption.value),
            fuseBoxOption: this.FuseBoxOptionToLabel(process.fuseBoxOption.value),
            commentsTime: process.comments?.value?.Time ? this.setDate(process.comments.value.Time) : '',
           })).filter(record => record.status.value[filter])
    },
    RPLAuthToName(id) {
      let user = RegionalProjectLeaders.find(user => user.id === id);
      return user ? user.name : ''
    },
    PowerOptionToLabel( option ) {
      let options = {
        'SufficientPowerWithOutStaticLoadbalancing': 'Er is genoeg capaciteit <strong>zonder</strong> static loadbalancing',
        'SufficientPowerWithStaticLoadbalancing': 'Er is genoeg capaciteit <strong>met</strong> static loadbalancing',
        'GridUpgrade': 'Er is niet genoeg capaciteit,  een netverzwaring aanvragen',
        'ReduceChargePoints': 'Er is niet genoeg capaciteit, verminder het aantal te plaatsen laadpunten',
        'NoChargePoints': 'Er is niet genoeg capaciteit, geen laadpunten plaatsen',

        'Sufficient power without static loadbalancing': 'Er is genoeg capaciteit zonder static loadbalancing',
        'Sufficient power when you apply loadbalancing': 'Er is genoeg capaciteit met static loadbalancing',
        'There is not enough power, grid upgrade': 'Er is niet genoeg capaciteit,  een netverzwaring aanvragen',
        'There is not enough power, reduce number of charging points': 'Er is niet genoeg capaciteit, verminder het aantal te plaatsen laadpunten',
        'There is not enough power, do not place any charging points': 'Er is niet genoeg capaciteit, geen laadpunten plaatsen'
      }

      return options[option] || ''
    },
    FuseBoxOptionToLabel(option) {
      let options = {
        'MinorChangeNeeded': 'Kleine aanpassing benodigd',
        'ReplacementNeeded': 'Grote aanpassing / vervanging benodigd',
        'NoChargePoints': 'Hoofdverdeelkast kan niet aangepast of vervangen worden, geen laadpunten plaatsen'
      }

      return options[option] || ''
    },

    setDate(value) {
      return value ? new Date(value).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1em;
}
.xsmall {
  font-size: 0.8em;
  color: #777
}
:deep(.center .p-column-header-content) {
  justify-content: center;
}
:deep(.p-datatable thead) {
  position: sticky;
  top: 52px;

  th {
    border-bottom: 1px solid #999 !important;
    background: #eee !important;
  }
}
:deep(.p-inputtext ) {
  border-color: #2196F3;
}
</style>

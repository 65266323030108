import DEFConfig from '@/config/defaults';
import AMSConfig from '@/config/amsterdam';
import PNCConfig from '@/config/park-n-charge';
import OPCConfig from '@/config/opcharge';
import VLAConfig from '@/config/vlaanderen';
import RVBConfig from '@/config/rvb';

/******************************************************************************
 * Select and export the Config of the active tenant
 */
const ConfigByTenant = {
  amsterdam: AMSConfig,
  opcharge: OPCConfig,
  'park-n-charge': PNCConfig,
  vlaanderen: VLAConfig,
  rvb: RVBConfig
};

export const Config = Object.assign(DEFConfig, ConfigByTenant[process.env.VUE_APP_TENANT] || {});

export const chargePointOperatorField = Config.chargePointOperatorField;
export const DateOfCommissioningFields = Config.DateOfCommissioningFields;
export const stepComponents = Config.stepComponents || {};
export const dataLayers = Config.dataLayers || {};

export const visibleRequestsByRealisationStatuses = Config.visibleRequestsByRealisationStatuses;

export const benchmarkMenu = Config.benchmarkMenu;
export const gridConnectionValues = Config.gridConnectionValues;
export const assetManagementFields = Config.assetManagementFields;
export const exportCsv = Config.exportCsv;
export const operatorsByMunicipalityCode = Config.operatorsByMunicipalityCode;
export const canResetDateValue = Config.canResetDateValue;
export const requestMapShowActiveRedMarker = Config.requestMapShowActiveRedMarker;
export const preparationDateFields = Config.preparationDateFields;
export const parkinglotSearch = Config.parkinglotSearch;
export const realisationListFields = Config.realisationListFields;
export const defaultRealisationTableFields = Config.defaultRealisationTableFields;
export const defaultRealisationStatusFilters = Config.defaultRealisationStatusFilters;
export const finUnit = Config.finUnit;
export const useStreetCabinetUploadField = Config.useStreetCabinetUploadField;
export const tour = Config.tour;
// Genrally uses default menu
export const AdminMenu = Config.AdminMenu || [];
export const ReportingMenu = Config.ReportingMenu || [];

export const LocationPickerDiameter = Config.LocationPickerDiameter || 200; // meters
export const RegionalProjectLeaders = Config.RegionalProjectLeaders || [];
/******************************************************************************
 * Helper methods
 */
export const roleToRoleLabel = role => {
  switch (role) {
    case 'cpo':
      return 'CPO';
    case 'municipality':
      return 'Gemeente';
    case 'admin':
      return 'Beheerder';
    case 'caseworker':
      return 'Medewerker';
    case 'customer':
      return 'Klant';
    case 'guest':
    case 'caseviewer':
      return 'Gast';
  }
  return '';
};

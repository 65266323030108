import store from '@/store'
import Process from '@/models/Process';
import Realisation from '@/models/Realisation';
import ProcessesConfig from '@/components/Table/configs/ProcessesConfig';
import { realisationListFields } from '@/config';

let mappedProcesses = []
const counts = {
  current: 0,
  total: 0
}

export function mapProcesses ({ processType, processes }) {
  const isRealisationType = processType === 'realisation'
  const ProcessType = isRealisationType ? Realisation : Process

  const records = processes.map(process => {
    return new ProcessType({ data: process.data, ref: process.ref['@ref'].id })
  })

  if (isRealisationType) {
    store.dispatch('realisations/setRecords', { records })
  }
  const mappedRecords = records.map(ProcessesConfig.dataMapper).slice();
  const formattedRecords = mappedRecords.map(record => realisationListFields({ record }))
  mappedProcesses = [...mappedProcesses, ...formattedRecords]

  const storeType = isRealisationType
    ? 'realisations/setRealisationTableRecords'
    : 'processes/setProcessTableRecords'

  store.dispatch(storeType, {
    records: mappedProcesses,
    processType
  });

  return formattedRecords
}

export function clearTempProcesses () {
  mappedProcesses = []
}

export async function setSelectedProcess (data) {
  store.dispatch('processes/setSelectedProcess', {
    process: new Process({ data, ref: null, newProcess: true })
  })
}

export async function fetchBaseAssetProcess ({ token, locationId, processType }) {
  // Check asset process type (manual, imported...) //
  let response = await fetchAssetByLocationId ({ token, locationId })
  const manualLocation = !!response.assets.editData // manual, imported //

  let data;

  if (!manualLocation) {
    const api = await fetch('/api/process', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        locationId
      })
    }).catch(err => console.log(err))

    if (!api.ok) {
      throw new Error('Getting realisation failed! ')
    }

    response = await api.json()

    data = setDataFromRealisationProcess(response, processType)
  } else {
    data = setDataFromAsset(response.assets, processType)
  }

  store.dispatch('processes/setSelectedProcess', {
    process: new Process({ data, ref: null, newProcess: true })
  })
}

export async function fetchAssetProcess ({ token, uuid }) {
  const api = await fetch('/api/process', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      uuid
    })
  }).catch(err => console.log(err))

  if (!api.ok) {
    throw new Error('Getting process failed! ')
  }

  const response = await api.json()
  const process = response.data.record

  store.dispatch('processes/setSelectedProcess', {
    process: new Process({ data: process.data, ref: process.ref['@ref'].id })
  })

  return process
}

export async function fetchProcessesByType ({ token, processType, code, after }) {

  const api = await fetch('/api/processlist', { // TODO: fix this...was processlist, but that endpoint is not filtering on CPO
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      processType, code, after
    })
  }).catch(err => console.log(err))

  if (!api.ok) {
    throw new Error(`Getting ${processType} processes failed!`)
  }

  const response = await api.json()


  counts.total = response.data.count
  counts.current += response.data.records.length
  store.dispatch('processes/setCounts', { counts });
  mapProcesses({ processType, processes: response.data.records })

  if (response.data.after) {
    return await fetchProcessesByType({ token, processType, code, after: response.data.after })
  }

  counts.total = 0
  counts.current = 0

  if (!code) {
    mappedProcesses = []
  }

  return mappedProcesses
}

export async function fetchAssetByLocationId ({ token, locationId }) {
  const api = await fetch('/api/assets', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      locationId
    })
  })
  if (!api.ok) {
    throw new Error('failed')
  }

  const response = await api.json()

  store.dispatch('processes/setSelectedAsset', { asset: response.data.assets })
  return response.data
}

const setDataFromRealisationProcess = (response, processType) => {
  const process = response.data.record
  process.data.realisationProcessData = JSON.parse(JSON.stringify(process.data))
  process.data.realisationProcessData.ref = process.ref['@ref'].id
  process.data.realisationCategory = process.data.category
  process.data.processType = processType

  const realisationProcessData = process.data.realisationProcessData

  const [rpLocation] = realisationProcessData.Locations

  const data = {
    processType: process.data.processType,
    realisationProcessData,
    realisationCategory: process.data.category,
    uuid: '',
    MunicipalityCode: realisationProcessData.MunicipalityCode,
    Locations: [{
      Municipality: realisationProcessData.MunicipalityCode,
      ChargePointOperator: rpLocation.ChargePointOperator,
      GridOperator: rpLocation.GridOperator,
      Contractor: rpLocation.Contractor
    }],
    locationId: realisationProcessData.locationId
  }

  return data
}

// eslint-disable-next-line no-unused-vars
const setDataFromAsset = (asset, processType) => {
  const ChargePointOperator = store.getters['tenant/getTenant'].tenantName
  const municipalityOptions = store.getters['tenant/getMunicipalityOptions']
  const MunicipalityCode = municipalityOptions.find(mun => mun.text === asset.municipality)?.value
  const [manualProcess] = asset.processes
  const gridOperatorAddress = asset.editData.gridOperatorAddress || null
  const manualProcessType = store.getters['tenant/getProcessTypes'].find(process => process.value === manualProcess.type)
  const { latitude, longitude } = asset.editData.coordinates

  const realisationProcessData = {
    processType: manualProcessType, // manual, import
    realisationCategory: 'request',
    uuid: '',
    MunicipalityCode,
    Locations: [{
      Municipality: MunicipalityCode,
      ChargePointOperator,
      GridOperator: asset.gridOperator,
      Contractor: asset.contractor || '',
      Location: {
        chargingpointUuid: null,
        chargingpointStatus: null,
        StreetName: asset.street,
        HouseNumber: asset.number,
        HouseNumberSuffix: '',
        PostalCode: asset.postalCode,
        CityName: asset.city,
        Province: '',
        Country: 'Netherlands',
        Latitude: latitude,
        Longitude: longitude,
        AdditionalCoordinates: []
      },
      CPOAdvice: {
        AdditionalCostDescription: '',
        PowerType: manualProcess.powerType,
        CPOAdvice: '',
        CPOComments: '',
        ChargingStationVersion: manualProcess.chargingStationVersion
      },
      ParkingSpots: {
        Type: 'station',
        NumberOfChargingStations: 1,
        NumberOfParkingSpots: 1,
        Identifiers: []
      },
      GridConnection: {
        RequestDate: '',
        GridOperatorNumber: asset.gridOperatorNumber,
        ConnectionValue: asset.gridConnectionValue,
        Address: gridOperatorAddress
      },
      PlannedRealisation: {
        PlannedDate: '',
        Permit: 'nvt'
      },
      DateOfCommissioning: {
        DateOfCommissioning: '',
        DateOfEnergyConnection: '',
        EAN: asset.EAN
      },
      Docs: {
        SideView: [],
        TopView: [],
      },
      FinUnit: asset.finUnit,
    }],
    locationId: asset.location,
    Docs: {
      LocationProposition: [],
      StreetCabinet: [],
      ParkingSpots: [],
      SideView: [],
      TopView: [],
      DeliveryDocumentation: []
    }
  }

  const data = {
    processType,
    realisationProcessData,
    realisationCategory: 'request',
    uuid: '',
    MunicipalityCode,
    Locations: realisationProcessData.Locations,
    locationId: realisationProcessData.locationId
  }

  return data
}

import Vue from 'vue';
//import { allMunicipalityOptions } from '@/services/allMunicipalityOptions';

const state = {
  tenant: process.env.VUE_APP_TENANT,
  tenantName: '',
  tenantAcronym: '',
  processType: {},
  image: {},
  featureAccess: {},
  mapOptions: {},
  visibleRecordsByStatus: {},
  usesPlannedRealisationFields: true,
  useAdditionalCoordinates: false,
  cpos: {},
  loading: false,
  tags: [],
  toasts: [],
  contractors: [],
  pids: [],
  workflows: {},
  workflowspecs: {},
  selectOptions: {},
  selectOptionLabelsByValue: {},
  municipalityOptions: [],
  chargingStationVersions: [],
  cancelReasons: [],
  onHoldReasons: [],
  reasonOptions: [],
  monitoring: {},
  defaultAssetTableFields: [],
  processTypes: [],
  chargingpointStatuses: [],
  processesConfig: {},
  createAssetForm: [],
  baseUrl: ''
};

const getters = {
  getTenant: state => {
    return { slug: state.tenant, name: state.tenantName, acronym: state.tenantAcronym };
  },
  isCurrentTenant:
    state =>
    ({ tenant }) =>
      state.tenant === tenant,
  getTags: state => state.tags,
  getEnabledTags: state => state.tags.filter(tag => !!tag.enabled && !tag.deleted_at),
  getExistingTags: state => state.tags.filter(tag => !tag.deleted_at),
  getLabelsByTagUuid: state =>
    state.tags.reduce((result, tag) => {
      result[tag.uuid] = tag.label;
      return result;
    }, {}),

  getRecordTagLabels:
    state =>
    ({ record }) => {
      return record.tags.map(uuid => state.tags.find(tag => uuid === tag.uuid)?.label);
    },

  getPIDs: state => state.pids,
  getBaseUrl: state => state.baseUrl,
  getEnabledPids: state => state.pids.filter(pid => !!pid.enabled),
  getLabelsByPidUuid: state =>
    state.pids.reduce((result, pid) => {
      result[pid.uuid] = pid.label;
      return result;
    }, {}),
  getToasts: state => state.toasts,
  getContractors: state => state.contractors,
  getExistingContractors: state => state.contractors.filter(contractor => !contractor.deleted_at),
  getEnabledContractors: state =>
    state.contractors.filter(contractor => !!contractor.enabled && !contractor.deleted_at),
  getLabelsByContractorUuid: state =>
    state.contractors.reduce((result, contractor) => {
      result[contractor.uuid] = contractor.label;
      return result;
    }, {}),

  getContractorByUuid:
    state =>
    ({ uuid }) =>
      state.contractors.find(contractor => contractor.uuid === uuid),

  getWorkflowUuidByName:
    state =>
    ({ name }) =>
      state.workflows[name] || null,
  getWorkflowSpecByUuid:
    state =>
    ({ uuid }) =>
      state.workflowspecs[uuid] || null,
  getWorkflowSpecByName:
    state =>
    ({ name }) => {
      let uuid = state.workflows[name] || null;
      return uuid ? state.workflowspecs[uuid] || null : null;
    },

  getSelectOptionsByName:
    state =>
    ({ name }) => {
      return state.selectOptions[name] || [];
    },
  getSelectOptionLabelByValue:
    state =>
    ({ name, value }) => {
      return (state.selectOptionLabelsByValue[name] || {})[value] || null;
    },

  getCategoryOptions: (state, getters) => {
    return getters.getSelectOptionsByName({ name: 'processCategories' });
  },
  getCategoryOptionsByContext:
    (state, getters) =>
    ({ context }) => {
      let options = getters.getSelectOptionsByName({ name: 'processCategories' });
      return context === null ? options : options.filter(option => option?.meta?.context === context);
    },
  getVkbStatuses: () => state.selectOptions.vkb.map(status => status.value),
  getMunicipalityOptions: state => {
    // const isVlaanderen = process.env.VUE_APP_TENANT !== 'park-n-charge'
    // const municipalityOptions = (isVlaanderen
    //   ? state.municipalityOptions
    //   : allMunicipalityOptions)

    return state.municipalityOptions.sort((a, b) => a.text.localeCompare(b.text))
  },
  getCharchingStationVersions: state => state.chargingStationVersions,
  getOnHoldReasons: state => state.onHoldReasons,
  getCancelReasons: state => state.cancelReasons,
  getReasonOptions: state => state.reasonOptions,
  getSteps: (state, getters, rootState, rootGetters) => {
    const type = rootGetters['processes/getCurrentProcessType'] || 'realisation'
    return state.processesConfig[type]?.steps
  },
  getProcessesConfig: state => state.processesConfig,
  //getSteps: state => state.steps,
  getImage: state => state.image,
  getMapOptions: state => state.mapOptions,
  getUsesPlannedRealisationFields: state => state.usesPlannedRealisationFields,
  getUseAdditionalCoordinates: state => state.useAdditionalCoordinates,
  getCpos: state => state.cpos, //.map(cpo => cpo.name),
  getMonitoringConfig: state => state.monitoring,
  getFeatureAccess: state => state.featureAccess,
  getVisibleRecordsByStatus: state => state.visibleRecordsByStatus,
  getDefaultAssetTableFields: state => state.defaultAssetTableFields,
  getProcessTypes: state => state.processTypes,
  getActiveProcessTypes: (state) => state.processTypes.filter(type => !type.hidden),
  getChargingpointStatuses: state => state.chargingpointStatuses,
  getAssetForm: state => state.createAssetForm
};
const actions = {
  setConfig ({ commit }, { config } ) {
    commit('setConfig', { config })
  },
  setToast(_, { message, error, variant, delay = 1000 }) {
    setTimeout(() => {
        this.$bvToast.toast(message, {
        title: variant ? 'Waarschuwing' : error ? 'Fout!' : 'Succes!',
        variant: variant || (error ? 'danger' : 'success'),
        toaster: 'b-toaster-top-right',
        solid: true,
        delay
      });
    }, delay);
  },

  loadConfig: async ({ state, commit }, { token }) => {
    if (state.loading) return;

    commit('setLoadingState', { loading: true });

    try {
      const response = await fetch('/api/config', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to load config');
      }

      const resp = await response.json();

      commit('setConfig', { config: resp.data });
    } finally {
      commit('setLoadingState', { loading: false });
    }
  },

  loadWorkflowSpecs: async ({ state, commit }, { token }) => {
    let uuids = Object.values(state.workflows);

    for (let i = 0; i < uuids.length; i++) {
      const api = await fetch('/api/workflowspecification', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          uuid: uuids[i]
        })
      });

      if (!api.ok) {
        throw new Error('Failed to load config');
      }

      const response = await api.json();

      commit('setWorkflowSpecByUuid', {
        uuid: uuids[i],
        spec: response.data
      });
    }
  }
};
const mutations = {
  setLoadingState(state, { loading }) {
    state.loading = loading;
  },

  setConfig(state, { config }) {
    if (config.tenant !== state.tenant) {
      throw new Error('Wrong tenant config');
    }

    state.tenantAcronym = config.tenantAcronym;
    state.tenantName = config.tenantName;
    state.image = config.tenantImage || config.image;
    state.featureAccess = config.featureAccess || {};
    state.mapOptions = config.mapOptions;
    state.visibleRecordsByStatus = config.visibleRecordsByStatus || {};
    state.usesPlannedRealisationFields = config.usesPlannedRealisationFields;
    state.useAdditionalCoordinates = config.useAdditionalCoordinates;
    state.cpos = Object.values(config.cpos) || [];
    state.tags = config.tags || [];
    state.pids = (config.pids || []).sort((a, b) => a.label.localeCompare(b.label));
    state.toasts = config.toasts || [];
    state.contractors = (config.contractors || []).sort((a, b) => a.label.localeCompare(b.label));
    state.workflows = config.workflows || {};
    state.selectOptions = config.selectOptions || {};
    state.baseUrl = config.baseUrl;
    state.municipalityOptions = config.municipalityOptions || [];
    state.chargingStationVersions = config.chargingStationVersions || [];
    state.cancelReasons = config.cancelReasons || [];
    state.onHoldReasons = config.onHoldReasons || [];
    state.reasonOptions = config.reasonOptions || [];
    state.processesConfig = config.processesConfig || {};
    state.createAssetForm = config.createAssetForm || []

    state.monitoring = config.monitoring || {};
    state.defaultAssetTableFields = config.defaultAssetTableFields || []
    state.processTypes = config.processTypes || []
    state.chargingpointStatuses = config.chargingpointStatuses || []

    state.selectOptionLabelsByValue = Object.keys(state.selectOptions).reduce((result, key) => {
      result[key] = state.selectOptions[key].reduce((optionLabelsByValue, option) => {
        optionLabelsByValue[option.value] = option.text || '';
        return optionLabelsByValue;
      }, {});
      return result;
    }, {});
  },

  setWorkflowSpecByUuid(state, { uuid, spec }) {
    Vue.set(state.workflowspecs, uuid, spec);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

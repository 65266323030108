export const chargingPointStatuses = ({ status }) => {
  if (!status) return
    return {
      'alert': 'Aandachtspunt',
      'rejected': 'Afgekeurd',
      'rejected-private': 'Afgekeurd privaat',
      'suggestion': 'Voorgesteld',
      'suggestion-private': 'Voorgesteld privaat',
      'definitive': 'Gevalideerd',
      'definitive-private': 'Gevalideerd privaat',
      'in-progress': 'In voorbereiding',
      'in-progress-private': 'In voorbereiding privaat',
      'realized': 'Gerealiseerd',
      'realized-private': 'Gerealiseerd privaat',
      'realized-proprietary': 'Gerealiseerd (eigen)',
      'realized-private-proprietary': 'Gerealiseerd privaat (eigen)',
      'fastcharger-rejected': 'Afgekeurde snellader',
      'fastcharger-realized': 'Gerealiseerde snellader',
      'fastcharger-suggestion': 'Voorgestelde snellader',
      'fastcharger-definitive': 'Gevalideerde snellader',
      'charging-hub-rejected': 'Afgekeurd laadplein',
      'charging-hub-suggestion': 'Voorgesteld laadplein',
      'charging-hub-definitive': 'Gevalideerd laadplein',
      'fastcharger-in-progress': 'Snellader in voorbereiding',
      'charging-hub-in-progress': 'Laadplein in voorbereiding',
      'charging-hub-realized': 'Gerealiseerd laadplein'
    }[status.trim()] || 'onbekend'
  }

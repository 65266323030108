<template>
  <div class="w-100">
    <div v-if="canDeleteProcess">
      <Button
        v-if="prime"
        type="button"
        icon="pi pi-trash"
        :badge="String(uuids.length)"
        badge-class="p-badge-primary"
        :class="['p-button p-button-danger px-3', selectedRowsClass]"
        @click="confirmDeleteRealisation"
      />
      <b-button
        v-else
        :variant="'outline-danger'"
        class="w-100"
        @click="confirmDeleteRealisation"
      >
        Verwijder proces
      </b-button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Button from 'primevue/button'

export default {
  name: 'RealisationDelete',
  components: {
    Button,
  },
  props: {
    uuids: {
      type: Array,
      default: () => [],
    },
    prime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    ...mapGetters('realisations', ['recordByUuid']),
    ...mapGetters('user', ['canDeleteProcess']),
    ...mapGetters('processes', ['getSelectedProcess']),
    ...mapGetters('assets', ['getAssets']),

    selectedRowsClass () {
      return !this.uuids.length && 'p-disabled p-button-secondary p-button-outlined'
    },
    isAssetProcess () {
      return this.getSelectedProcess?.processType?.value
    }
  },
  methods: {
    errorMessage (response) {
      const caseRef = response.record ? response.record.case?.full : ''
      //const chargingpointId = response.location ? `${response.location.code}-${response.location.properties?.id}` : ''

      return this.error
        ? `Het proces ${caseRef} kon niet worden verwijderd.`
        : `Het proces ${caseRef} is verwijderd.` // en laadpaal ${chargingpointId} zijn verwijderd.
    },

    confirmDeleteRealisation () {
      const h = this.$createElement
      const message = this.uuids.length > 1
        ? 'Weet u zeker dat u alle geselecteerde processen wilt verwijderen?'
        : 'Weet u zeker dat u dit proces wilt verwijderen?'

      const titleVNode = h('div', { domProps: { innerHTML: message } })
      const messageVNode = h('div',
        [
          'Deze actie kan niet ongedaan worden en betekent dat:',
          h('ul',
            { class: ['mt-2'] },
            [
              h('li', this.uuids.length > 1
                ? 'De processen definitief uit Workflow worden verwijderd.'
                : 'Dit proces definitief uit Workflow wordt verwijderd.',
              ),
              h('li', 'Eventueel gebruikte locaties uit EV Maps worden vrijgegeven ter gebruik in een nieuw proces.'),
              h('li', 'Eventueel aangemaakte locaties in EV Maps worden verwijderd.'),
              h('li', 'Eventuele laadpaal Aanvragen van bewoners worden ontkoppeld van dit proces, de aanvrager ontvangt daarvan notificatie per e-mail.'),
            ],
          ),
        ])

      this.$bvModal.msgBoxConfirm(
        [messageVNode],
        {
          title: [titleVNode],
          okVariant: 'danger',
          okTitle: 'Bevestigen',
          cancelTitle: 'Annuleren',
          size: 'lg',
        })
        .then(confirmed => {
          if (confirmed) {
            this.handleDeleteRealisation()
          }
        })
    },

    async handleDeleteRealisation() {
      try {
        this.error = false

        await Promise.all(
          this.uuids.map(async uuid => {
            const record = this.recordByUuid({ uuid }) || this.getSelectedProcess

            if (record) {
              const token = await this.$auth.getTokenSilently()
              const api = await fetch('/api/process_delete', {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ record }),
              })

              const response = await api.json()

              if (response.statusCode === 500) {
                this.error = true

                if (this.$route.params.uuid) {
                  this.$store.dispatch('tenant/setToast', { vm: this, message: this.errorMessage(response.data), error: this.error })
                }
                return
              }

              this.$store.dispatch('realisations/deleteRealisation', { data: response.data })
              this.$store.dispatch('processes/setSelectedAsset', { asset: null })
              if (response.data.record) {
                this.$store.dispatch('processes/deleteProcess', { uuid: response.data.record.uuid })

                if (this.getAssets) {
                  this.$store.dispatch('assets/deleteAssetProcess', { record: response.data.record })
                }
              }

               // Disconnect possible linked request
              if (record.requestUuids.length) {
                record.requestUuids.map(requestUuid => {
                  const data = {
                    type: 'remove',
                    requestUuid,
                    realisationUuid: record.uuid,
                  }
                  this.$store.dispatch('relations/setRelation', { data })
                })
              }

              if (!this.prime) {
                this.$store.dispatch('tenant/setToast', { vm: this, message: this.errorMessage(response.data), error: this.error })
                if (!this.error) {
                  const name = this.isAssetProcess ? 'LocationList': 'ProcessList'
                  this.$router.replace({
                    name,
                    ...(this.isAssetProcess ? {
                      params: {
                        locationId: record.locationId.full
                      }
                    } : {})
                  })
                }
              } else {
                this.$emit('realisationDeleted', { record })
              }

              return response
            }

            return record
          }),
        )
        .catch(err => console.log('catch', err))

        this.$emit('allRealisationsDeleted')

        if (this.prime) {
          this.$store.dispatch('tenant/setToast', {
            vm: this,
            message: 'Alle geselecteerde realisaties zijn verwijderd!',
            error: false,
            delay: 100,
          })
        }
      } catch (e) {
        this.error = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .p-button {
    &.p-button-icon-only {
      width: auto;
    }
    ::v-deep .p-badge {
      color: #D32F2F;
    }
  }
</style>

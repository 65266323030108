


const Request = function({ ref, data }) {
  // console.log("Request Data", data)

  const contact = data.baseData ? (data.baseData.contact || {}) : {}
  contact.name = `${contact.firstname} ${contact.surname || contact.lastname}`.trim()

  const addressData = data.baseData ? data.baseData.address : {}

  let address = `${addressData.street} ${addressData.streetNumber} ${addressData.streetNumberSuffix || ''}`.trim()
  address = `${address}, ${addressData.postalCode}`
  address = `${address}, ${addressData.city}`

  let common = this.commonStructure({ ref, data })

  data = Object.assign(common, {
    tenant: 'power-go',
    contact: contact,
    address: addressData,
    remark: data.baseData.remark || '',

    additional: data.additionalData,

    geographyCode: data.municipalityCode,

    summary: Object.assign(common.summary, {
      name: contact.name,
      address,
    }),
    search: {
      postalcode: data.baseData.address && data.baseData.address.postalCode ? data.baseData.address.postalCode.replace(' ', '').trim() : '',
      city: data.baseData.address && data.baseData.address.city ? data.baseData.address.city.toLowerCase().trim() : '',
    },

    coordinates: {
      nomatch: data.processMeta && data.processMeta.geocoder ? (data.processMeta.geocoder.nomatch || false) : false,
      lat: data.baseData.coordinates ? (data.baseData.coordinates.lat || null) : null,
      lng: data.baseData.coordinates ? (data.baseData.coordinates.lng || null) : null,
    },
    submitted_at: common.created_at,
  })

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Request.prototype.getMunicipality = function() {
  try {
    return this.geographyCode || this.additional.gemcode.code || null
  } catch (e) {
    return null
  }
}


export default Request

import store from '@/store';
import { periodInDays } from '@/helpers/date';

const config = {
  LocationPickerDiameter: 200,

  // Enable the possibility to reset or cancel already saved date
  canResetDateValue: false,

  parkinglotSearch: false,

  chargePointOperatorField: {
    type: 'string',
    title: 'CPO',
    disabled: true
  },
  /**
   * DateOfCommissioning fields (all tenants exc. Amsterdam)
   */

  DateOfCommissioningFields: {
    DateOfCommissioning: {
      type: 'string',
      title: 'Datum in bedrijf genomen',
      format: 'date'
    },
    DateOfEnergyConnection: {
      type: 'string',
      title: 'Datum aanmelding energieleverancier',
      format: 'date'
    },
    EAN: {
      type: 'string',
      title: 'EAN',
      format: 'text'
    }
  },

  preparationDateFields: {
    GridOperatorContract: {
      type: 'string',
      title: 'Tekendatum offerte netbeheerder',
      format: 'date'
    },
    ChargePointOrderDate: {
      type: 'string',
      title: 'Besteldatum laadpaal',
      format: 'date'
    }
  },

  visibleRequestsByRealisationStatuses: {
    completed: false,
    cancelled: true,
    onhold: true
  },

  requestMapShowActiveRedMarker: true,

  operatorsByMunicipalityCode: {},
  /**
   * Street cabinet (Straatkast) upload field
   */
  useStreetCabinetUploadField: false,

  ReportingMenu: [
    {
      type: 'link',
      label: 'Voor klanten',
      to: {
        name: 'RVBCustomers'
      }
    },
    {
      type: 'segment',
      label: 'Laadpunten',
      items: [{
        type: 'link',
        label: 'Laadpunten per stelsel',
        to: {
          name: 'RVBChargingpointsByObjectType'
        }
      }, {
        type: 'link',
        label: 'Laadpunten per PID',
        to: {
          name: 'RVBChargingpointsByPID'
        }
      }]
    },
    {
      type: 'segment',
      label: 'Proces informatie',
      items: [{
        type: 'link',
        label: 'Aantal netverzwaringen',
        to: {
          name: 'RVBGridImprovements'
        }
      }]
    }
  ],

  AdminMenu: [
    {
      type: 'segment',
      label: 'Configuratie',
      items: [
        {
          type: 'link',
          label: 'Beschikbare tags',
          to: {
            name: 'TagsConfig'
          }
        },
        {
          type: 'link',
          label: 'PIDs',
          to: {
            name: 'PidsConfig'
          }
        }
      ]
    }
  ],

  benchmarkMenu: [
    { name: 'Gemeente', target: 'getMunicipalityOptions' },
    { name: 'Netbeheerder', target: 'gridOperators' },
    { name: 'Aannemer', target: 'getEnabledContractors' },
    { name: 'Type realisatieproces', target: 'getCategoryOptions' },
    { name: 'Aantal palen per RP', target: 'recordsByNumberOfChargingStations' }
  ],

  gridConnectionValues: [
    '1 x 25 A',
    '1 x 35 A',
    '1 x 40 A',
    '3 x 25 A',
    '3 x 35 A',
    '3 x 50 A',
    '3 x 63 A',
    '3 x 80 A',
    '3 x 100 A',
    '3 x 125 A',
    '3 x 160 A',
    '3 x 250 A',
    '3 x 400 A',
    '3 x 500 A',
    '3 x 630 A',
    '160 kVA',
    '250 kVA',
    '400 kVA',
    '630 kVA',
    '1750 kVA',
    '2000 kVA'
  ],

  defaultRealisationTableFields: () => {
    // const getCharchingStationVersions = store.getters['tenant/getCharchingStationVersions'];

    return [
      { field: 'reference', label: 'Referentie', width: 200 },
      { field: 'pidNumber', label: 'PID', width: 150 },
      { field: 'buildingCode', label: 'Gebouwnummer', width: 200 },
      { field: 'address', label: 'Adres', width: 500 },
      { field: 'customer', label: 'Klant', width: 300 },
      { field: 'step', label: 'Stap', width: 500, fn: 'setStepLabels', matchMode: 'filterByArrayEquals' }
    ];
  },

  defaultRealisationStatusFilters: {
    all: { label: 'Alles', count: 0 },
    active: { label: 'Actief', count: 0 },
    onhold: { label: 'On hold', count: 0 },
    completed: { label: 'Afgerond', count: 0 },
    cancelled: { label: 'Geannuleerd', count: 0 },
    // municipality: { label: 'Gemeente', count: 0, badge: 'success', step: true, stepGroup: true },
    // cpo: { label: 'CPO', count: 0, badge: 'success', step: true }
  },

  realisationListFields: ({ record }) => {
    if (record.progress_days === '-' && record.vkb !== 'Verlopen') {
      const lastStepStart = record.flow.state.filter(step => step.started_at).at(-1)?.started_at;
      const duration = {
        start: lastStepStart,
        end: 0,
        finished: false
      };
      record.progress_days = periodInDays({ duration });
    }

    // const progressDays =
    //   typeof record.progress_days === 'number'
    //     ? record.progress_days // + (record.progress_days === 1 ? ' dag' : ' dagen')
    //     : '';

    return {
      uuid: { value: record.uuid, hidden: true },
      status: { value: record.categories, hidden: true },
      updated_at_nr: { value: record.updated_at_nr, hidden: true },
      processType: { value: record.processType, hidden: true },
      reference: { value: record.reference, link: record.uuid },
      pidNumber: { value: record.pidNumber },
      pidUuid: { value: record.pidUuid },
      buildingCode: { value: record.buildingCode },
      address: { value: record.address },
      region: { value: record.region },
      projectLeader: { value: record.projectLeader },
      objectManager: { value: record.objectManager },
      assetManager: { value: record.assetManager },
      numberOfExistingChargePoints: { value: record.numberOfExistingChargePoints },
      chargePointNeedsGovernmentOffices: { value: record.chargePointNeedsGovernmentOffices },
      chargePointNeedsNewProgram: { value: record.chargePointNeedsNewProgram },
      extraNeedChargePoints: { value: record.extraNeedChargePoints },
      totalChargePointsStillToBePlaced: { value: record.totalChargePointsStillToBePlaced },
      chargePointsRealized: { value: record.chargePointsRealized },
      powerOption: { value: record.powerOption },
      fuseBoxOption: { value: record.fuseBoxOption },
      allPartiesSignedDate: { value: record.allPartiesSignedDate },
      comments: { value: record.comments },
      customer: { value: record.customer },
      objectType: { value: record.objectType },
      step: { value: record.step }
    };
  },

  exportCsv: {
    file: {
      name: 'Assets',
      acronym: 'AM'
    },
    worksheetColumns: [], // Dynamically injected
    formatRecords: records => {
      const baseUrl = store.getters['tenant/getBaseUrl'];

      return records.map(record => {
        const columns = config.exportCsv.worksheetColumns;

        Object.keys(record)
          .filter(key => !record[key].hidden && config.exportCsv.worksheetColumns.some(column => key === column.key))
          .map(key => {
            const objHasProp = prop => Object.keys(record[key]).some(key => key === prop);

            const index = columns.findIndex(c => c.key === key);
            if (index !== -1) {
              const link = columns[index].link || objHasProp('link');
              const uuid = record.processUuid || record[key].link;
              if (link) {
                // Hyperlink excel column
                const processType =
                  record.processType?.value === 'realisation' || record.type === 'Realisatie' ? 'realisatie' : 'proces';
                return (columns[index].value = {
                  text: record[key].value || record[key],
                  url: `${baseUrl}${processType}/${uuid}` // record uuid
                });
              }

              let value = objHasProp('value') ? record[key].value : record[key];

              if (key === 'numberOfChargingStations' || key === 'numberOfParkingSpots') {
                value = Number(value) || '';
              }

              const validValue = value && typeof value !== 'object' && value !== '-' ? value : '';
              columns[index].value = validValue;
            }
          });

        return columns.map(column => column.value); // Return sorted according to the selected order of columns
      });
    }
  },
  supportEmailAddress: 'support@evtools.nl',

  RegionalProjectLeaders: [
    { name: 'Aldert Klugkist', id: 'auth0|60112d465238fb006960d979' },
    { name: 'Annemarie Eriks', id: 'auth0|62bea828c1f5b79cdb36897c' },
    { name: 'Claire Hoffman', id: 'auth0|602e24e2a38832007006f30a' },
    { name: 'Esther Beijlsmit', id: 'auth0|602e076ed20a6f006ffd531f' },
    { name: 'Esther Rinsampessij', id: 'auth0|602e074be199770069d2819c' },
    { name: 'Koen Fehrmann', id: 'auth0|60b5ceccea19400033f5098a' },
    { name: 'Mark Sodaar', id: 'auth0|62f617c14e9a75601b24ec74' },
    { name: 'Martijn Kuijs', id: 'auth0|602e0717d20a6f006ffd5191' },
    { name: 'Simon Datema', id: 'auth0|602e06ff19a8a10071819e1d' },
    { name: 'Stefan Lathouwers', id: 'auth0|602e07341819a10070dca6d6' },
    { name: 'Johan Nederveen', id: 'auth0|62bea77f33ef82d54328fbd9' },
    { name: 'Walter Bruisten', id: 'auth0|602e069281ae2c006ae28695' },
    { name: 'Kevin Buijing', id: 'auth0|63bed0a7ceb10aafc7bf3c34' }
  ]
};

export default config;

<template>
  <div
    :key="$route.fullPath"
    class="Overlay__Wrapper"
  >
    <slot />

    <div
      class="Overlay"
      :class="{ 'Overlay--visible': isOverlayVisible }"
    >
      <div
        class="Overlay__ClickBlokker"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Overlay',
  computed: {
    ...mapGetters('overlay', [
      'isOverlayVisible',
    ]),
  },
  methods: {
    ...mapMutations('overlay', [
      'hideOverlay',
    ]),
    handleClick() {
      this.hideOverlay()
    },
  },
}
</script>

<style lang="scss">
.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  background: black;
  transition: opacity 0.3s ease-in-out;
  z-index: 999; // Required to overlap Bootstrap-vue (file) inputs
  user-select: none;

  &__Wrapper {
    position: relative;
  }

  &__ClickBlokker {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
    z-index: 999;
    opacity: 0.99;
  }

  &--visible {
    opacity: 0.6;
  }
  &--visible &__ClickBlokker {
    display: block;
  }
}
</style>

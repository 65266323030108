


const Request = function({ ref, data }) {
  // console.log("Request Data", data)

  const contact = data.baseData ? (data.baseData.contact || {}) : {}
  contact.name = `${contact.firstname} ${contact.surname || contact.lastname}`.trim()

  const addressData = data.baseData ? data.baseData.address : {}

  let address = `${addressData.street} ${addressData.streetNumber} ${addressData.streetNumberSuffix || ''}`.trim()
  address = `${address}, ${addressData.postalCode}`
  address = `${address}, ${addressData.city}`

  let Docs = data.Docs || {}
  let Files = data.Files || []

  let common = this.commonStructure({ ref, data })

  data = Object.assign(common, {
    tenant: 'go-ral',
    contact: contact,
    address: addressData,

    additional: data.additionalData,

    summary: Object.assign(common.summary, {
      name: contact.name,
      address,
    }),
    search: {
      postalcode: data.baseData.address && data.baseData.address.postalCode ? data.baseData.address.postalCode.replace(' ', '').trim() : '',
      city: data.baseData.address && data.baseData.address.city ? data.baseData.address.city.toLowerCase().trim() : '',
    },

    coordinates: {
      nomatch: data.processMeta && data.processMeta.geocoder ? (data.processMeta.geocoder.nomatch || false) : false,
      lat: data.baseData.coordinates ? (data.baseData.coordinates.lat || null) : null,
      lng: data.baseData.coordinates ? (data.baseData.coordinates.lng || null) : null,
    },
    submitted_at: common.created_at,

    Docs: {
      vehicle: Docs.vehicle || [],
      parkingspot: Docs.parkingspot || [],
    },
    Files: {
      vehicle: Files.filter(file => file.category === 'vehicle').map(file => file.uuid) || [],
      parkingspot: Files.filter(file => file.category === 'parkingspot').map(file => file.uuid) || [],
    },
  })

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Request.prototype.getMunicipality = function() {
  try {
    return this.additional.gemcode.code || null
  } catch (e) {
    return null
  }
}


export default Request

import store from '@/store';
import { periodInDays } from '@/helpers/date';
import { chargingPointStatuses } from '@/../shared/valueholders/chargingPointStatuses';
import { finUnit } from '@/config';
import router from '../router'

// For Tour if async action is required first in order to target selected DOM element //
const isElementLoaded = async (selector) => {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
              observer.disconnect();
              resolve(document.querySelector(selector));
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}

const config = {
  LocationPickerDiameter: 200,

  // Enable the possibility to reset or cancel already saved date
  canResetDateValue: false,

  parkinglotSearch: false,

  chargePointOperatorField: {
    type: 'string',
    title: 'CPO',
    disabled: true
  },
  /**
   * DateOfCommissioning fields (all tenants exc. Amsterdam)
   */

  DateOfCommissioningFields: {
    DateOfCommissioning: {
      type: 'string',
      title: 'Datum in bedrijf genomen',
      format: 'date'
    },
    DateOfEnergyConnection: {
      type: 'string',
      title: 'Datum aanmelding energieleverancier',
      format: 'date'
    },
    EAN: {
      type: 'string',
      title: 'EAN',
      format: 'text'
    }
  },

  preparationDateFields: {
    GridOperatorContract: {
      type: 'string',
      title: 'Tekendatum offerte netbeheerder',
      format: 'date'
    },
    ChargePointOrderDate: {
      type: 'string',
      title: 'Besteldatum laadpaal',
      format: 'date'
    }
  },

  visibleRequestsByRealisationStatuses: {
    completed: false,
    cancelled: true,
    onhold: true
  },

  requestMapShowActiveRedMarker: true,

  operatorsByMunicipalityCode: {},
  /**
   * Street cabinet (Straatkast) upload field
   */
  useStreetCabinetUploadField: false,

  tour: {
    date: '24012025',
    email: 'support@evtools.nl',
    // allRoutes: {
    //   name: 'allRoutes',
    //   access: 'canAccessAssetManagement',
    //   steps: [
    //     {
    //       target: '#mainNavbar .nav-item:nth-child(3)',
    //       header: {
    //         title: 'Uitbreiding Workflow',
    //       },
    //       content: '<p>Uw Workflow is uitgebreid met “Locatiebeheer” functionaliteit. </br></br> Onder Locaties vindt u het overzicht van reeds gerealiseerde locaties. Op gerealiseerde locaties kunt u aanvullende processen in gang zetten waaronder: bijplaatsen, verplaatsen, vervangen en verwijderen. U vindt het overzicht van de specifieke processen onder de dropdown met processen. </br></br> Heeft u wensen voor aanvullende processen of aanpassingen binnen deze processen, dan horen wij dat graag via <a href="mailto"support@evtools.nl">support@evtools.nl</a> of uw directe aanspreekpunt.</p>',
    //       params: {
    //         placement: 'bottom',
    //       },
    //     },
    //     {
    //       target: '#processesNavBar .dropdown-menu',
    //       header: {
    //         title: 'Overzichtspagina',
    //       },
    //       content: '<p>Elk proces heeft een eigen overzichtspagina waar u de specifieke informatie uit de stappen van dat proces terug vindt.</p>',
    //       params: {
    //         placement: 'bottom-start',
    //       },
    //       before: () => new Promise((resolve) => {
    //         const selector = document.querySelector('#processDropdown__BV_toggle_')
    //         selector.click()
    //         setTimeout(() => {
    //           resolve()
    //         }, 250)
    //       })
    //     },
    //   ],
    // },
    realisationStep8: {
      name: 'realisationStep8',
      steps: [
        {
          target: '.charger-types',
          header: {
            title: 'Laadpaal id\'s per type'
          },
          content: '<p>U kunt vanaf heden per Laadpaal ID aangeven of het een principaal of satelliet laadpaal betreft. Standaard wordt het eerste ID gezien als de principaal en elk volgende ID als satelliet.</p>',
          params: {
            placement: 'bottom-start',
          },
          before: () => new Promise((resolve) => {
            isElementLoaded('.charger-types').then(() => {
              setTimeout(() => {
                resolve();
              }, 250);
              // const selector = document.querySelector('.charger-types');
              // selector.click();
              // setTimeout(() => {
              //   resolve();
              // }, 250);
            });
          })
        }
      ]
    },
    get realisationStep9 () {
      // Amsterdam //
      if (router.currentRoute.fullPath.endsWith('stap-9')) {
        const step = config.tour.realisationStep8
        step.name = 'realisationStep9'
        return step
      }
      return false
    },
    // ProcessList: {
    //   name: 'ProcessList',
    //   steps: [
    //     {
    //       target: '#selectionColumns',
    //       header: {
    //         title: 'Kolom selectie & export',
    //       },
    //       content: '<p>U kunt elke overzichtspagina zo instellen dat u alleen de voor u relevante kolommen ziet. Wanneer u een export maakt wordt alleen de data uit de door u geselecteerde kolommen met de daarin toepaste filters, geexporteerd naar het Excel bestand.</p>',
    //       params: {
    //         placement: 'bottom-start',
    //       },
    //     },
    //     {
    //       target: '#resetTable',
    //       header: {
    //         title: 'Herstel Tabel',
    //       },
    //       content: '<p>U kunt de kolommen herstellen naar het volledige overzicht via het blauwe pijltje. Filters en kolom selecties worden daarmee gereset zodat u alle data inzichtelijk heeft.</p>',
    //       params: {
    //         placement: 'bottom-start',
    //       },
    //     }
    //   ]

    // },
    // LocationList: {
    //   name: 'LocationList',
    //   access: 'canAccessAssetManagement',
    //   steps: [
    //     {
    //       target: '#locationTable .p-datatable-row-expansion',
    //       header: {
    //         title: 'Locatiebeheer processen'
    //       },
    //       content: '<p>De tabel heeft voor elke Locatie een eigen regel met in de eerste kolom de mogelijkheid die regel uit te klappen via het pijltje. Wanneer de Locatie regel is uitgeklapt ziet u alle processen die er op die locatie zijn geweest. Hiermee heeft u van elke Locatie de historie snel inzichtelijk. De actuele Locatie is het resultaat van alle eerdere processen op die locatie.</p>',
    //       params: {
    //         placement: 'bottom',
    //       },
    //       before: () => new Promise((resolve) => {
    //         isElementLoaded('.has-completed-process').then(() => {
    //           const selector = document.querySelector('.has-completed-process');
    //           selector.click();
    //           setTimeout(() => {
    //             resolve();
    //           }, 250);
    //         });
    //       })
    //     },
    //     {
    //       target: '#newProcessBtn',
    //       header: {
    //         title: 'Nieuw proces starten',
    //       },
    //       content: '<p>U kunt een Locatie beheerproces starten via de blauwe knop “Maak nieuw proces aan”. Deze knop is alleen blauw en te gebruiken wanneer er op de geselecteerde locatie geen ander proces actief is.</p><p>De beschikbare processen die u kunt starten op een bestaande locatie zijn:<ul><li>Bijplaatsen van een laadpaal op een bestaande locatie</li><li>Verplaatsen van een laadpaal op een bestaande locatie waarbij het adres gelijk blijft</li><li>Verwijderen van een laadpaal op een bestaande locatie</li><li>Vervangen van een laadpaal op een bestaande locatie</li> </ul></p>',
    //       params: {
    //         placement: 'right-start',
    //       }
    //     },
    //     {
    //       target: '#locationTable .p-selectable-row td',
    //       header: {
    //         title: 'Locatie Opmerkingen'
    //       },
    //       content: '<p>U kunt interne opmerkingen plaatsen bij een locatie. Deze staan los van eventuele opmerkingen binnen een proces op de locatie en zijn alleen voor uw organisatie zichtbaar.</p>',
    //       params: {
    //         placement: 'right-start',
    //       },
    //     }
    //   ]
    // },

    // Add tour dynamically to all Realisation.Step routes //
    // addTourStepToRealisationStepRoutes: ({ target }) => {

    //   const doc = {
    //     target,
    //     isOnAllSteps: true,
    //   }
    //   config.steps.forEach(step => {
    //     const stepTour = config.tour['RealisationStep' + step.step]
    //     if (stepTour?.steps) {
    //       console.log("found")
    //       config.tour['RealisationStep' + step.step].steps.push(doc)
    //     } else {
    //       config.tour[`RealisationStep${step.step}`] =
    //       {
    //         name: `realisatieproces stap ${step.step}`,
    //         steps: [{ ...doc }],
    //       }
    //       console.log(config.tour[`RealisationStep${step.step}`]  )
    //     }
    //   })
    // },
  },

  ReportingMenu: [
    {
      type: 'link',
      label: 'Key Metrics',
      to: {
        name: 'KeyMetrics'
      }
    },
    {
      type: 'segment',
      label: 'Aanvragen',
      items: [
        {
          type: 'link',
          label: 'Beoordelingen',
          to: {
            name: 'RequestJudgement'
          }
        }
      ]
    },
    {
      type: 'segment',
      label: 'Realisatie',
      items: [
        {
          type: 'link',
          label: 'Statusoverzicht',
          to: {
            name: 'RealisationProcessStatus'
          }
        },
        {
          type: 'link',
          label: 'Doorlooptijd van stappen',
          to: {
            name: 'ProcessTimeInSteps'
          }
        }
      ]
    }
  ],

  AdminMenu: [
    {
      type: 'segment',
      label: 'Configuratie',
      items: [
        {
          type: 'link',
          label: 'Beschikbare tags',
          to: {
            name: 'TagsConfig'
          }
        },
        {
          type: 'link',
          label: 'Aannemers',
          to: {
            name: 'ContractorsConfig'
          }
        }
      ]
    }
  ],

  benchmarkMenu: [
    { name: 'Gemeente', target: 'getMunicipalityOptions' },
    { name: 'Netbeheerder', target: 'gridOperators' },
    { name: 'Aannemer', target: 'getEnabledContractors' },
    { name: 'Type realisatieproces', target: 'getCategoryOptions' },
    { name: 'Aantal palen per RP', target: 'recordsByNumberOfChargingStations' }
  ],

  gridConnectionValues: [
    '1 x 25 A',
    '1 x 35 A',
    '1 x 40 A',
    '3 x 25 A',
    '3 x 35 A',
    '3 x 50 A',
    '3 x 63 A',
    '3 x 80 A',
    '3 x 100 A',
    '3 x 125 A',
    '3 x 160 A',
    '3 x 250 A',
    '3 x 400 A',
    '3 x 500 A',
    '3 x 630 A',
    '160 kVA',
    '250 kVA',
    '400 kVA',
    '630 kVA',
    '1750 kVA',
    '2000 kVA'
  ],

  defaultRealisationTableFields: isUserCPO => {
    const getCharchingStationVersions = store.getters['tenant/getCharchingStationVersions'];

    return [
      { field: 'reference', label: 'Referentie', width: 192 },
      { field: 'street', label: 'Straat', width: 300 },
      { field: 'houseNumber', label: 'Huisnummer', width: 140, filterDisabled: true },
      { field: 'houseNumberSuffix', label: 'Toevoeging', width: 140, filterDisabled: true },
      { field: 'postalCode', label: 'Postcode', width: 160 },
      { field: 'city', label: 'Plaats', width: 280, filterDisabled: true },
      { field: 'address', label: 'Adres', width: 500 },
      //{ field: 'country', label: 'Land', width: 150 },

      {
        field: 'municipalityName',
        label: 'Gemeente',
        width: 285,
        fn: 'setMunicipalityOptions',
        matchMode: 'filterByArrayEquals'
      },

      { field: 'lat', label: 'Latitude', width: 192, filterDisabled: true },
      { field: 'lng', label: 'Longitude', width: 192, filterDisabled: true },
      { field: 'step', label: 'Stap', width: 300, fn: 'setStepLabels', matchMode: 'filterByArrayEquals' },
      { field: 'progress_days', label: 'Dagen in huidige stap', width: 210, filterDisabled: true },
      ...( process.env.VUE_APP_TENANT === 'vlaanderen'
        ? [{
          field: 'cpo',
          label: 'CPO',
          width: 192,
          fn: 'setCpoOptions',
          matchMode: 'filterByArrayEquals',
          filterDisabled: isUserCPO
        }] : []
      ),
      {
        field: 'chargingpointStatus',
        label: 'Laadpaal status',
        width: 192,
        fn: 'setChargingpointStatuses',
        matchMode: 'filterByArrayEquals'
      },
      { field: 'currentStatus', label: 'Status', width: 132, fn: 'setStatusOptions', matchMode: 'filterByArrayEquals', filterDisabled: true },
      { field: 'currentStatusMessage', label: 'Onhold & Annulerings reden', width: 544, filterDisabled: true },
      { field: 'parkinglotIds', label: 'Parkeerplaatsen', width: 260, filterDisabled: true },
      { field: 'requestCount', label: 'Aanvragen', width: 192, filterDisabled: true },
      {
        field: 'category',
        label: 'Type',
        width: 192,
        fn: 'setCategoryOptions',
        matchMode: 'filterByArrayEquals'
      },
      { field: 'numberOfChargingStations', label: 'Aantal laadpalen', width: 192, filterDisabled: true },
      { field: 'vkb', label: 'VKB Status', width: 192, fn: 'setVkbStatusOptions', matchMode: 'filterByArrayEquals' },
      { field: 'VKBPublicationDate', label: 'Datum VKB publicatie', width: 256, filterDisabled: true },
      { field: 'VKBDefinitiveDate', label: 'Datum VKB definitief', width: 236, filterDisabled: true },
      { field: 'gridOperator', label: 'Netbeheerder', width: 244, fn: 'gridOperator', matchMode: 'filterByArrayEquals' },
      { field: 'gridOperatorNumber', label: 'Netbeheerdernummer', width: 244, filterDisabled: true },
      {
        field: 'connectionValue',
        label: 'Aansluitwaarde',
        width: 192,
        fn: 'setGridConnectionValues',
        matchMode: 'filterByArrayEquals',
        filterDisabled: !config.gridConnectionValues?.length
      },
      { field: 'EAN', label: 'EAN', width: 192, filterDisabled: true },
      { field: 'chargerID', label: 'Laadpaal ID\'s', width: 192, filterDisabled: true },

      {
        field: 'chargingStationVersion',
        label: 'Uitvoering laadpaal',
        width: 227,
        fn: 'setCharchingStationVersions',
        matchMode: 'filterByArrayEquals',
        filterDisabled: !getCharchingStationVersions.length
      },
      { field: 'powerType', label: 'Type power', width: 192, fn: 'setPowerTypeOptions', matchMode: 'filterByArrayEquals' },
      {
        field: 'contractor',
        label: 'Aannemer',
        width: 192,
        fn: 'setContractors',
        matchMode: 'filterByArrayEquals'
      },
      { field: 'realisationStartDate', label: 'Realisatie startdatum', width: 248, filterDisabled: true },
      { field: 'gridConnectionDate', label: 'Datum aanvraag netaansluiting', width: 317, filterDisabled: true },
      { field: 'realisation_date', label: 'Geplande realisatiedatum', width: 278, filterDisabled: true },
      { field: 'updated_at', label: 'Gewijzigd op', width: 192, filterDisabled: true },
      { field: 'dateOfCommissioning', label: 'Datum in bedrijf genomen', width: 280, filterDisabled: true },
      { field: 'dateOfDecommissioning', label: 'Datum uit bedrijf genomen', width: 280, filterDisabled: true },
      { field: 'tagcount', label: 'Aantal tags', width: 192, filterDisabled: true },
      {
        field: 'tags',
        label: 'Tags',
        width: 322,
        fn: 'setTagOptions',
        matchMode: 'commaSeparatedArrayOR'
      },
      ...(finUnit ? [{ field: 'finUnit', label: 'SPV', width: 192, fn: 'setFinUnitOptions', matchMode: 'filterByArrayEquals' }] : [])
    ];
  },

  defaultRealisationStatusFilters: {
    all: { label: 'Alles', count: 0 },
    active: { label: 'Actief', count: 0 },
    onhold: { label: 'On hold', count: 0 },
    completed: { label: 'Afgerond', count: 0 },
    cancelled: { label: 'Geannuleerd', count: 0 },
    municipality: { label: 'Gemeente', count: 0, badge: 'success', step: true, stepGroup: true },
    cpo: { label: 'CPO', count: 0, badge: 'success', step: true }
  },

  realisationListFields: ({ record }) => {
    const categoryLabelGetter = store.getters['tenant/getSelectOptionLabelByValue'];
    const getContractorByUuidGetter = store.getters['tenant/getContractorByUuid'];
    const tagLabels = store.getters['tenant/getRecordTagLabels']({ record }).join(', ');

    if (record.progress_days === '-' && record.vkb !== 'Verlopen') {
      const lastStepStart = record.flow.state.filter(step => step.started_at).at(-1)?.started_at;
      const duration = {
        start: lastStepStart,
        end: 0,
        finished: false
      };
      record.progress_days = periodInDays({ duration });
    }

    const progressDays =
      typeof record.progress_days === 'number'
        ? record.progress_days // + (record.progress_days === 1 ? ' dag' : ' dagen')
        : '';

    return {
      uuid: { value: record.uuid, hidden: true },
      status: { value: record.categories, hidden: true },
      updated_at_nr: { value: record.updated_at_nr, hidden: true },
      processType: { value: record.processType, hidden: true },

      reference: { value: record.reference, link: record.uuid },
      street: { value: record.street },
      houseNumber: { value: record.houseNumber },
      houseNumberSuffix: { value: record.houseNumberSuffix },
      postalCode: { value: record.postalCode },
      city: { value: record.city },
      address: { value: record.address },
      //country: { value: record.country },

      municipalityName: { value: record.municipalityName },
      lat: { value: record.lat },
      lng: { value: record.lng },
      step: { value: record.step },
      progress_days: { value: progressDays },
      chargingpointStatus: {
        value: chargingPointStatuses({ status: record.chargingpointStatus }),
        icon: record.chargingpointStatus
      },
      chargerID: { value: record.chargerIds },
      currentStatus: { value: record.currentStatus },
      ...( process.env.VUE_APP_TENANT === 'vlaanderen'
        ? { cpo: { value: record.cpo } } : {}
      ),
      currentStatusMessage: { value: record.currentStatusMessage },
      parkinglotIds: { value: record.parkinglotIds },
      requestCount: { value: record.requestUuids.length },
      category: { value: categoryLabelGetter({ name: 'processCategories', value: record.category }) || record.category },
      numberOfChargingStations: { value: record.numberOfChargingStations },
      vkb: { value: record.vkb, label: 'VKB Status' },
      VKBPublicationDate: { value: record.VKBPublicationDate, fn: 'setDate' },
      VKBDefinitiveDate: { value: record.VKBDefinitiveDate, fn: 'setDate' },
      gridOperator: { value: record.gridOperator },
      gridOperatorNumber: { value: record.gridOperatorNumber },
      connectionValue: { value: record.connectionValue },
      EAN: { value: record.EAN },
      chargingStationVersion: { value: record.chargingStationVersion },
      powerType: { value: record.powerType },
      contractor: { value: getContractorByUuidGetter({ uuid: record.contractor })?.label },
      realisationStartDate: { value: record.realisationStartDate, fn: 'setDate' },
      gridConnectionDate: { value: record.gridConnectionDate, fn: 'setDate' },
      realisation_date: { value: record.realisation_date, fn: 'setDate' },
      updated_at: { value: record.updated_at_nr, fn: 'setDate' },
      dateOfCommissioning: { value: record.dateOfCommissioning, fn: 'setDate' },
      dateOfDecommissioning: { value: record.dateOfDecommissioning, fn: 'setDate' },
      tagcount: { value: record.tagcount },
      tags: { value: tagLabels },
      ...(finUnit ? { finUnit: { value: record.finUnit } } : {})
    };
  },

  exportCsv: {
    file: {
      name: 'Assets',
      acronym: 'AM'
    },
    worksheetColumns: [], // Dynamically injected
    formatRecords: records => {
      const baseUrl = store.getters['tenant/getBaseUrl'];

      return records.map(record => {
        const columns = config.exportCsv.worksheetColumns;

        Object.keys(record)
          .filter(key => !record[key]?.hidden && config.exportCsv.worksheetColumns.some(column => key === column.key))
          .map(key => {
            const objHasProp = prop => record[key] && Object.keys(record[key]).some(key => key === prop)

            const index = columns.findIndex(c => c.key === key);
            if (index !== -1) {
              const link = columns[index].link || objHasProp('link')
              const uuid = record.processUuid || record[key]?.link
              if (link) {
                // Hyperlink excel column
                const processType = record.processType?.value === 'realisation' || record.type === 'Realisatie' ? 'realisatie' : 'proces'
                return (columns[index].value = {
                  text: record[key].value || record[key],
                  url: `${baseUrl}${processType}/${uuid}` // record uuid
                });
              }

              let value = objHasProp('value') ? record[key].value : record[key];

              if (key === 'numberOfChargingStations' || key === 'numberOfParkingSpots') {
                value = Number(value) || '';
              }

              const validValue = (value && typeof value !== 'object' && value !== '-') ? value : ''
              columns[index].value = validValue;
            }
          });

        return columns.map(column => column.value); // Return sorted according to the selected order of columns
      });
    }

  },

  vkbStatuses: ({ record, Location }) => {
    const now = Date.now();

    let vkb = 'Onbekende status';
    let vkb_sorting = 9999;

    if (record.status.vkb) {
      vkb = 'Onherroepelijk';
      vkb_sorting = 1;
    } else if (Location) {
      // Location
      const TrafficDecisionDetails = Location.getTrafficDecisionDetails();
      if (TrafficDecisionDetails.TrafficDecisionNotApplicable) {
        vkb = 'Geen VKB';
        vkb_sorting = 99;
      } else if (!TrafficDecisionDetails.PublicationDate) {
        vkb = 'Nog te publiceren';
        vkb_sorting = 100;
      } else if (TrafficDecisionDetails.ConfirmedDate) {
        vkb = 'Onherroepelijk';
        vkb_sorting = 1;
      } else if (TrafficDecisionDetails.DefinitiveDate) {
        const date = new Date(TrafficDecisionDetails.DefinitiveDate).getTime();

        // Already passed
        if (date < now) {
          vkb = 'Verlopen';
          vkb_sorting = 2;
        } else {
          const diffInMs = date - now;
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          const diffInWeeks = diffInDays / 7;

          if (diffInDays < 2) {
            vkb = 'Nog max 2 dagen';
            vkb_sorting = 3;
          } else if (diffInDays < 7) {
            vkb = 'Nog max 7 dagen';
            vkb_sorting = 4;
          } else if (diffInDays <= 14) {
            vkb = 'Nog max 14 dagen';
            vkb_sorting = 5;
          } else if (diffInWeeks >= 2 && diffInWeeks < 4) {
            vkb = 'Nog 2-4 weken';
            vkb_sorting = 6;
          } else if (diffInWeeks >= 4 && diffInWeeks <= 6) {
            vkb = 'Nog 4-6 weken';
            vkb_sorting = 7;
          } else if (diffInWeeks > 6) {
            vkb = 'Nog meer dan 6 weken';
            vkb_sorting = 8;
          }
        }
      }
    }

    return { vkb, vkb_sorting };
  },
  // steps: store.getters['tenant/getSteps'], // for addTourStepToRealisationStepRoutes if uncommented
  supportEmailAddress: 'support@evtools.nl'
};

// const tourRoutesMatch = /^RealisationStep\d?$|^RequestDetail$/
// Object.keys({ ...localStorage }).map(key => {
//   if (tourRoutesMatch.test(key)) {
//     localStorage.removeItem(key)
//   }
// })

// const isTourSeen = Object.keys({ ...localStorage }).some(key => key.startsWith('RealisationStep'))
// if (!isTourSeen) {
//   config.tour.addTourStepToRealisationStepRoutes({ target: '#document-download' })
// }

export default config;

<template>
  <div
    class="Row"
    :style="style"
  >
    <template v-for="(field,index) in fields">
      <Instruction
        v-if="field.props.type === 'instruction'"
        v-bind="field.props"
        :key="`fieldInstruction_${index}`"
      />
      <FieldValue
        v-else-if="viewer || field.readonly || field.viewer"
        v-bind="field.props"
        :key="`fieldValue_${index}`"
        :name="name({ name: field.name })"
      />
      <FormField
        v-else
        v-bind="field.props"
        :key="`field_${index}`"
        :name="name({ name: field.name })"
        :size="size"
        :tabindex="tabindex"
        :can-reset="canReset"
        :select-disabled="selectDisabled"
        :planned-date-alert="plannedDateAlert"
        :debounce="debounce"
        @blur="handleBlur({ name: field.name })"
        @change="($event) => $emit('change', $event)"
        @update="value => $emit('update', { name: field.name, value })"
        @input="value => handleInput({ name: field.name, value })"
      />
    </template>
  </div>
</template>

<script>

import Instruction from '@/components/form/Instruction'
import FormField from '@/components/form/FormField'
import FieldValue from '@/components/form/FieldValue'

export default {
  name: 'Row',
  components: { FormField, FieldValue, Instruction },
  props: {
    /**
     * The field specification
     */
    fields: {
      type: Array,
      required: true,
    },
    plannedDateAlert: {
      type: String,
      default: '',
    },
    selectDisabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: 0
    },
    /**
     * CSS Grid "grid-template-columns" value
     */
    columns: {
      type: String,
      required: true,
    },
    /**
     *
     */
    path: {
      type: String,
      default: '',
    },

    /**
     * Viewer Mode
     */
    viewer: {
      type: Boolean,
      default: false,
    },

    /**
     * Bootstrap sizes (sm, lg or empty for regular)
     */
    size: {
      type: String,
      default: 'sm',
    },
    debounce: {
      type: [Number, String],
      default: '0',
    },
    canReset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        'grid-template-columns': this.columns,
      }
    },
  },
  methods: {
    name({ name }) {
      return this.path === '' ? name : `${this.path}.${name}`
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name: this.name({ name }), // add path info
      })
    },
    handleInput({ name, value }) {
      this.$emit('input', {
        value,
        name: this.name({ name }), // add path info
      })
    },
  },
}
</script>

<style>
.Row {
  display: grid;
  column-gap: 0.5rem;
}
</style>

<template>
  <b-navbar
    class="PageNav justify-content-between position-fixed fixed-top"
    type="dark"
  >
    <!-- type="light" variant="light" -->

    <!-- <HeaderLogo class="flex-shrink-1 mr-3" /> -->
    <router-link :to="{ name: 'Dashboard' }">
      <EVToolsLogo
        context="navbar"
        class="flex-shrink-1 mr-3"
      />
    </router-link>

    <b-navbar-nav class="align-self-center my-0 flex-grow-1">
      <b-nav-item
        class="no-border pt-2"
        :to="{ name: 'Dashboard' }"
      >
        Workflow
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      id="mainNavbar"
      class="d-flex flex-grow-1 mr-3"
      align="right"
      variant="light"
    >
      <b-nav-item
        v-if="showRequestList"
        :to="{ name: 'RequestList' }"
      >
        Aanvragen
      </b-nav-item>
      <div v-if="showRealisationProcessList">
        <b-nav-item
          v-if="!hasOnlyRealisationProcessType"
          id="processesNavBar"
          class="process-dropdown d-flex justify-content-center"
          :active="isRouteActive"
        >
          <b-dropdown
            id="processDropdown"
            ref="processDropdown"
            size="sm"
            :toggle-class="['process', { 'link active': isRouteActive }]"
            :text="selectedProcessLabel"
            right
          >
            <b-dropdown-item-button
              v-for="processType in processTypes"
              id="processDropdown"
              :key="processType.value"
              :active="activeProcessItem({ processType })"
              @click="handleLoadProcessType({ processType })"
            >
              <div><span>{{ `${processType.label}sen` }} <small>({{ processType.acronym }})</small></span></div>
            </b-dropdown-item-button>
          </b-dropdown>
        </b-nav-item>
        <b-nav-item
          v-else
          :to="{ name: 'ProcessList', params: { type: getType() } }"
          :active="isRouteActive"
        >
          Processen
        </b-nav-item>
      </div>

      <b-nav-item
        v-if="showLocationList"
        :to="{ name: 'LocationList' }"
      >
        Locaties
      </b-nav-item>
      <b-nav-item
        v-if="canAccessReporting"
        :to="{ name: 'Reporting' }"
      >
        Rapportages
      </b-nav-item>
    </b-navbar-nav>
    <StartProcessButton
      v-if="showRealistionProcessButton"
      class="mr-3"
      variant="light"
      size="sm"
    />

    <router-link
      v-if="canAccessManuals"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Manuals' }"
    >
      <SvgIcon
        icon="circle-question"
        :has-fill="true"
      />
    </router-link>

    <router-link
      v-if="canAccessConfig"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Config' }"
    >
      <SvgIcon
        icon="cog-regular"
        :has-fill="true"
      />
    </router-link>

    <span
      class="NavBar__Profile d-inline-flex align-center mr-3"
      :class="{ 'NavBar__Profile--visible': showingProfile }"
      @click="showProfile"
    >
      <SvgIcon
        icon="user-regular"
        :has-fill="true"
      />
    </span>
    <!-- <b-nav align="right">
      <b-nav-item @click="logout">
        <b-button variant="light">
          Uitloggen
          <b-icon icon="box-arrow-right"></b-icon>
        </b-button>
      </b-nav-item>
    </b-nav> -->

    <ProfileCard
      v-if="showingProfile"
      :style="{ right: profileCardOffset }"
    />
  </b-navbar>
</template>

<script>
// import HeaderLogo from '@/components/HeaderLogo.vue'
import EVToolsLogo from '@/components/EVToolsLogo.vue'
import ProfileCard from '@/components/profile/ProfileCard.vue'
import SvgIcon from '@/components/common/SvgIcon'

import StartProcessButton from '@/components/realisation/StartProcessButton'

import { mapGetters, mapMutations } from 'vuex'

const uuid = require('uuid')

export default {
  name: 'PageNav',
  components: {
    // HeaderLogo,
    EVToolsLogo,
    ProfileCard,
    SvgIcon,
    StartProcessButton,
  },
  data() {
    return {
      showingProfile: false,
      selectedProcessType: null,
      processTranslation: {
        realisatie: 'Realisatieprocessen',
        bijplaatsing: 'Bijplaatsprocessen',
        verplaatsing: 'Verplaatsprocessen',
        verwijdering: 'Verwijderprocessen',
        vervanging: 'Vervangprocessen',
        verzwaring: 'Verzwaarprocessen'
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRealisationProcesses',
      'canStartRealisationProcess',
      'canAccessAssetManagement',
      'canAccessManuals',
      'canAccessConfig',
      'canAccessReporting',
    ]),
    ...mapGetters('overlay', ['isOverlayVisible']),
    ...mapGetters('tenant', ['getActiveProcessTypes', 'getCategoryOptions']),
    ...mapGetters('processes', ['getCurrentProcessType']),

    showRequestList() {
      return this.canAccessRequests
    },
    showRealisationProcessList() {
      return this.canAccessRealisationProcesses
    },
    showRealistionProcessButton() {
      return this.canStartRealisationProcess
    },
    showLocationList() {
      return this.canAccessAssetManagement
    },

    selectedProcessLabel () {
      return `${this.selectedProcessType?.label}sen`
    },
    processTypes () {
      // For the time being, filter out the 'capacity-increase' and private process //
      return this.getActiveProcessTypes.filter(type => !['CIP'].includes(type.acronym))
    },
    hasOnlyRealisationProcessType () {
      return this.getActiveProcessTypes.every(process => process.value === 'realisation') || this.getActiveProcessTypes.length === 1
    },

    /**
     * We need to avoid the profile card covering up the scrollbar of the main page content
     */
    profileCardOffset() {
      return '1.25em' // `${getScrollbarWidth()}px`
    },
    isRouteActive () {
      const routeName = this.$route.name?.toLowerCase() || ''
      return /^ProcessList|^Step|^RealisationStep|^new/i.test(this.$route.name) ||
             (this.getCurrentProcessType && routeName.startsWith(this.getCurrentProcessType))
    },
  },
  watch: {
    isOverlayVisible() {
      if (! this.isOverlayVisible) {
        this.showingProfile = false
      }
    },
    showingProfile() {
      if (this.showingProfile) {
        this.showOverlay()
        this.unfreezeOverlay()
      } else {
        this.hideOverlay()
      }
    },
    '$route': {
      immediate: true,
      handler (route) {
        const type = route.params.type || 'realisatie'
        const categories = this.getCategoryOptions.map(category => category.value)

        this.selectedProcessType = this.getActiveProcessTypes.find(process => {
          return categories.includes(type)
            ? process.value === 'realisation'
            : [process.routeType, process.label.toLowerCase()].includes(type)
        })
        // Set processType to 'realisation' for non-asset-process routes (e.g. Rapportages) where atm. only RP can be used
        if (!this.isRouteActive) {
          this.$store.dispatch('processes/setCurrentProcessType', { processType: 'realisation' })
        }
      }
    }
  },
  created () {

  },
  methods: {
    ...mapMutations('overlay', [
      'hideOverlay',
      'showOverlay',
      'unfreezeOverlay',
    ]),
    activeProcessItem ({ processType }) {
      return processType.value === this.selectedProcessType?.value
    },
    handleLoadProcessType ({ processType }) {
      this.selectedProcessType = processType
      if (this.$route.name !== 'ProcessList') {
        this.$router.push({ name: 'ProcessList', params: { type: processType.routeType } })
      } else {
        this.$router.replace({ params: { type: processType.routeType } })
      }
    },
    handleOpenNewRecord() {
      this.$router.push({
        name: 'Realisation',
        params: {
          uuid: uuid.v4(), // generate a v4 uuid for the new record
        },
      })
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    showProfile() {
      this.showingProfile = ! this.showingProfile
    },
    getType() {
      return process.env.VUE_APP_TENANT === 'rvb'? 'privaat': 'realisatie'
    }
  },
}
</script>

<style lang="scss">
.PageNav {
  // max-width: 1200px;
  margin: 0 auto;
  min-height: 52px;
  // margin-bottom: 30px;

  // EV Tools primary
  background: #041E42 !important;
  padding: 0px 0px 0px 7px !important;

  .badge {
    top: -10px;
    position: relative;
    right: 3px;
  }
  .SvgIcon {
    font-size: 1.75rem;
    fill: white;
    cursor: pointer;

    &:hover {
      fill: darken(white, 20%);
    }
  }

  &__description {
    height: 1em;
    color: white;
    // text-transform: uppercase;

    @media (max-width: 1200px) {
      span {
        display: none;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }
  }

  .ProfileCard, .NavBarMenu {
    position: absolute;
    top: calc(80px - 0.75rem);
    box-sizing: 0px 1px 3px 0px #dfe2e5;
  }

  .process-dropdown {
    width: 175px;
    .process {
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      font-size: 16px;
      padding-top: 0;
      color: rgba(255, 255, 255, 0.5);
    }

    .dropdown-item {
      &.active {
        background: none;
        color: inherit;
        border: 1px solid #041E42;
      }
    }
  }
}
</style>

import { formatPostalCode } from '@/helpers/string';

import AmsterdamRequest from '@/models/amsterdam/Request';
import OPCRequest from '@/models/opcharge/Request';
import GORRequest from '@/models/go-ral/Request';
import DHARequest from '@/models/den-hartog/Request';
import PNCRequest from '@/models/park-n-charge/Request';
import VLARequest from '@/models/vlaanderen/Request';
import DCHRequest from '@/models/dutch-charge/Request';
import PWGRequest from '@/models/power-go/Request';
import OCHRequest from '@/models/orange-charging/Request';
import EVTRequest from '@/models/evtools/Request';
import STPRequest from '@/models/stella-power/Request';
import MKERequest from '@/models/mick-e/Request';
import PrivateRequest from '@/models/private/Request';

const leadingZero = num => `0${num}`.slice(-2);

const formatTime = date => [date.getHours(), date.getMinutes()].map(leadingZero).join(':');

//import { formatDate, formatShortDate } from '@/helpers/date.js'

/******************************************************************************
 * Request
 */
let RequestByTenant = {
  amsterdam: AmsterdamRequest,
  opcharge: OPCRequest,
  'go-ral': GORRequest,
  'den-hartog': DHARequest,
  'park-n-charge': PNCRequest,
  vlaanderen: VLARequest,
  'dutch-charge': DCHRequest,
  'power-go': PWGRequest,
  'orange-charging': OCHRequest,
  'stella-power': STPRequest,
  'mick-e': MKERequest,
  'evtools': EVTRequest,
  'rvb': PrivateRequest
};

const Request = RequestByTenant[process.env.VUE_APP_TENANT] || (() => {});

const retrieveLatestJudgement = function ({ data }) {
  if (!Array.isArray(data.judgement)) {
    return data.judgement ?? {};
  }

  return data.judgement.sort((a, b) => ((a.processed_at || 0) < (b.processed_at || 0) ? 1 : -1))[0] ?? {};
};

/******************************************************************************
 * Shared methods
 */
if (!Request.prototype.formatPostalCode) {
  Request.prototype.formatPostalCode = formatPostalCode;
}

Request.prototype.getRef = function () {
  return this.ref;
};
Request.prototype.getModelName = function () {
  return 'Request';
};
Request.prototype.setTags = function ({ Tags }) {
  this.Tags = Tags;
};
Request.prototype.hasTags = function () {
  return !!(Array.isArray(this.Tags) && this.Tags.length !== 0);
};
Request.prototype.getTags = function () {
  return Array.isArray(this.Tags) ? this.Tags : [];
};

if (!Request.prototype.getMunicipality) {
  Request.prototype.getMunicipality = function () {
    return null;
  };
}

/******************************************************************************
 * Data formatting at contruction that is shared between all tenants
 */
Request.prototype.commonStructure = function ({ ref, data }) {
  const latestJudgement = retrieveLatestJudgement({ data });

  let processed_at = 0;
  let processed_at_short = {
    date: '',
    time: ''
  };
  let processed_at_nr = 0;
  if (latestJudgement && latestJudgement.processed_at) {
    let processedDate = new Date(latestJudgement.processed_at);
    processed_at = `${processedDate.toLocaleDateString('nl-NL')} ${formatTime(processedDate)}`;

    processed_at_short = {
      date: processedDate
        .toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' })
        .replace('. ', ' \''),
      time: formatTime(processedDate)
    };
    processed_at_nr = latestJudgement.processed_at;
  }

  let submitted_at = 'Onbekend';
  let submitted_at_short = {
    date: '',
    time: ''
  };
  let submitted_at_nr = 0;

  if (data.submitted_at) {
    let submittedDate = new Date(data.submitted_at);
    submitted_at = `${submittedDate.toLocaleDateString('nl-NL')} ${formatTime(submittedDate)}`;

    submitted_at_short = {
      date: submittedDate
        .toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' })
        .replace('. ', ' \''),
      time: formatTime(submittedDate)
    };
    submitted_at_nr = data.submitted_at;
  }
  let created_at = 'Onbekend';
  if (data.created_at) {
    let createdDate = new Date(parseInt(data.created_at, 10));
    created_at = `${createdDate.toLocaleDateString('nl-NL')} ${formatTime(createdDate)}`;

    if (!data.submitted_at) {
      submitted_at_short = {
        date: createdDate
          .toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' })
          .replace('. ', ' \''),
        time: formatTime(createdDate)
      };
      submitted_at_nr = data.created_at;
    }
  }

  let record = {
    limited: !!data.limited,
    ref: ref,
    uuid: data.uuid,
    source: data.source,

    raw: data,
    docs: data.docs || {},

    counter: data.case.counter,
    case_ref: data.case.full,
    status: data.status.id,

    editJudgement: data.editJudgement, // temporary value, set in SummaryTable.vue and consumed in Judgement.vue
    judgement: latestJudgement,

    contact: {},
    address: {},
    summary: {
      name: '',
      address: '',
      processed_at_short,
      processed_at_nr,
      submitted_at_short,
      submitted_at_nr
    },
    search: {},
    additional: {},
    coordinates: {
      nomatch: false,
      lat: null,
      lng: null
    },

    // Leans on defaults
    processed_at,
    submitted_at,
    created_at,

    // The realisation process connection is initiated by the Realisation object
    realisationUuid: null,
    // Connected process
    connectedProcess: data.connectedProcess || null,

    Tags: data.Tags || [],

    // Documents
    Docs: {},

    /**
     * Files. (Docs v2)
     */
    Files: {},

    // Comments
    comments: data.comments || []
  };

  return record;
};

export default Request;

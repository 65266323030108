
import { isObject } from '@/services/validation'

export default function({ ref, data }) {

  const contact = data.baseData ? data.baseData.contact : {}
  const addressData = data.baseData ? data.baseData.address : {}

  let address = `${addressData.street} ${addressData.streetNumber} ${addressData.streetNumberSuffix || ''}`.trim()
  address = `${address}, ${addressData.postalCode}`
  address = `${address}, ${addressData.city}`

  let common = this.commonStructure({ ref, data })

  let title = contact && isObject(contact.title) ? contact.title?.title : ''
  let name = contact ? `${title} ${contact.firstname} ${contact.surname || contact.lastname}`.replace('  ', ' ').trim() : ''

  data = Object.assign(common, {
    tenant: 'opcharge',
    contact: contact,
    address: addressData,

    additional: data.additionalData,

    summary: Object.assign(common.summary, {
      name,
      address,
    }),
    search: {
      postalcode: data.baseData.address && data.baseData.address.postalCode ? data.baseData.address.postalCode.replace(' ', '').trim() : '',
      city: data.baseData.address && data.baseData.address.city ? data.baseData.address.city.toLowerCase().trim() : '',
    },

    coordinates: {
      nomatch: data.processMeta && data.processMeta.geocoder ? (data.processMeta.geocoder.nomatch || false) : false,
      lat: data.baseData.coordinates ? (data.baseData.coordinates.lat || null) : null,
      lng: data.baseData.coordinates ? (data.baseData.coordinates.lng || null) : null,
    },
    submitted_at: common.created_at,
  })

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="hasAccess">
    <h3
      id="table_5"
      class="mt-3"
    >
      Laadpunten per PID
    </h3>
    <div class="d-flex gap-1">
      <div
        v-for="(filter, key) in statusFilters.filters"
        :key="key"
        class="d-flex align-items-center"
      >
        <Button
          type="button"
          :class="selectedStatusFilter === key ? 'p-button-primary p-button-sm' : statusFilters.class"
          :label="filter.label"
          :badge="String(filter.count)"
          :badge-class="`p-badge-${selectedStatusFilter === key ? 'primary' : filter.badge || 'secondary'}`"
          @click="handleFilter({ filter: key })"
        />
      </div>
    </div>
    <div class="card mt-3">
      <data-table
        v-if="processes.length"
        data-key="uuid.value"
        :value="processes"
        class="processes-table p-datatable-sm"
        striped-rows
        sort-field="objectTypeSort"
        :sort-order="1"
      >
        <Column header="PID">
          <template #body="{ data }">
            {{ data.pidNumber }}
          </template>
        </Column>
        <Column header="Status">
          <template #body="{ data }">
            <div class="mb-2">
              {{ data.status }}
            </div>
            <div>{{ data.statusTwo }}</div>
          </template>
        </Column>
        <Column
          header="Behoefte"
          header-class="center"
          :styles="{ 'text-align': 'center' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.demand }}
            </div>
            <div
              v-if="data.demandTwo !== ''"
              class="mt-2"
            >
              {{ data.demandTwo }}
            </div>
          </template>
        </Column>
        <Column
          header="ExtraBehoefte"
          header-class="center"
          :styles="{ 'text-align': 'center' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.extraDemand }}
            </div>
            <div
              v-if="data.extraDemandTwo !== ''"
              class="mt-2"
            >
              {{ data.extraDemandTwo }}
            </div>
          </template>
        </Column>
        <Column
          header="Totaal nog te plaatsen"
          header-class="center"
          :styles="{ 'text-align': 'center' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.stillToBePlaced }}
            </div>
            <div
              v-if="data.stillToBePlacedTwo !== ''"
              class="mt-2"
            >
              {{ data.stillToBePlacedTwo }}
            </div>
          </template>
        </Column>
        <Column
          header="Aantal laadpunten gerealiseerd"
          header-class="center"
          :styles="{ 'text-align': 'center' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.realized }}
            </div>
            <div
              v-if="data.realizedTwo !== ''"
              class="mt-2"
            >
              {{ data.realizedTwo }}
            </div>
          </template>
        </Column>
      </data-table>
    </div>
  </div>
  <div v-else>
    U heeft geen toegang tot klantrapportages
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { mapGetters } from 'vuex';

export default {
  name: 'RVBChargingpointsByPID',
  components: {
    DataTable,
    Column,
    Button
  },

  data() {
    return {
      processes: [],
      selectedStatusFilter: 'all',
      statusFilters: {
        filters: {
          all: { label: 'Alles', count: 0 },
          active: { label: 'Actief', count: 0 },
          onhold: { label: 'On hold', count: 0 },
          completed: { label: 'Afgerond', count: 0 },
          cancelled: { label: 'Geannuleerd', count: 0 }
        },
        class: 'p-button-sm p-button-outlined p-button-secondary',
      },
    }
  },

  computed: {
    ...mapGetters('processes', ['getProcessTableRecords']),
    ...mapGetters('user', [
      'securityCheck'
    ]),
    ...mapGetters('tenant', [
      'getLabelsByPidUuid'
    ]),
    hasAccess() {
      return this.securityCheck({ attribute: 'reporting' })
    }
  },

  watch: {
    getProcessTableRecords: {
      immediate: true,
      handler(value) {
        if (value.length) {
          this.processes = this.dataPresentationMapper(value)

          this.statusFilters.filters.all.count = value.length
          this.setStatusFiltersCounts(value)
        }
      },
    },
  },

  methods: {
    dataPresentationMapper: (records) => {
      const entry = {
        pidNumber: '',
        // completed
        status: 'In bedrijf',
        demand: 0,
        extraDemand: 0,
        stillToBePlaced: 0,
        realized: 0,
        // not completed
        statusTwo: 'Niet in bedrijf',
        demandTwo: 0,
        extraDemandTwo: 0,
        stillToBePlacedTwo: 0,
        realizedTwo: 0,
        recordStatus: '',
      }

      const data = {
        total: {
          pidNumber: 'Totaal',
          objectTypeSort: 'ZZ',
          status: '',
          demand: 0,
          extraDemand: 0,
          stillToBePlaced: 0,
          realized: 0,
          statusTwo: '',
          demandTwo: '',
          extraDemandTwo: '',
          stillToBePlacedTwo: '',
          realizedTwo: '',
        },
      }

      records.forEach(record => {
        const pidNumber = record.pidNumber?.value
        const completed = !!record?.allPartiesSignedDate?.value

        if (!data[pidNumber]) {
          data[pidNumber] = Object.assign({}, entry, { pidNumber: pidNumber || '-', objectTypeSort: pidNumber })
        }

        const demand = record.chargePointNeedsGovernmentOffices.value
        const extraDemand = record.extraNeedChargePoints.value
        const stillToBePlaced = record.totalChargePointsStillToBePlaced.value
        const realized = record.chargePointsRealized.value

        if (completed) {
          data[pidNumber].demand = data[pidNumber].demand + parseInt(demand, 10)
          data[pidNumber].extraDemand = data[pidNumber].extraDemand + parseInt(extraDemand, 10)
          data[pidNumber].stillToBePlaced = data[pidNumber].stillToBePlaced + parseInt(stillToBePlaced, 10)
          data[pidNumber].realized = data[pidNumber].realized + parseInt(realized, 10)
        } else {
          data[pidNumber].demandTwo = data[pidNumber].demandTwo + parseInt(demand, 10)
          data[pidNumber].extraDemandTwo = data[pidNumber].extraDemandTwo + parseInt(extraDemand, 10)
          data[pidNumber].stillToBePlacedTwo = data[pidNumber].stillToBePlacedTwo + parseInt(stillToBePlaced, 10)
          data[pidNumber].realizedTwo = data[pidNumber].realizedTwo + parseInt(realized, 10)
        }

        data.total.demand = data.total.demand + parseInt(demand, 10)
        data.total.extraDemand = data.total.extraDemand + parseInt(extraDemand, 10)
        data.total.stillToBePlaced = data.total.stillToBePlaced + parseInt(stillToBePlaced, 10)
        data.total.realized = data.total.realized + parseInt(realized, 10)
      })
      return Object.values(data)
    },

    setStatusFiltersCounts(records) {
      const data = records || this.processes
      Object.values(this.statusFilters.filters).forEach(filter => filter.count = 0)

      data.map(record => {
        const statuses = record.status.value
        Object.keys(statuses).forEach(status => statuses[status] ? this.statusFilters.filters[status].count++ : null)
      })
    },

    handleFilter({ filter }) {
      if (this.selectedStatusFilter === filter) return
      this.selectedStatusFilter = filter
      this.processes = this.dataPresentationMapper(this.getProcessTableRecords.filter(record => record.status.value[filter]))
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
}

:deep(.center .p-column-header-content) {
  justify-content: center;
}

:deep(.p-datatable .p-datatable-tbody > tr:last-child) {
  font-weight: bold;
}

:deep(.p-datatable thead) {
  position: sticky;
  top: 52px;

  th {
    border-bottom: 1px solid #999 !important;
    background: #eee !important;
  }
}
.gap-1 {
  gap: 1em;
}

.xsmall {
  font-size: 0.8em;
  color: #777
}
</style>

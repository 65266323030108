<template>
  <div style="display:none" />
</template>

<script>
/* eslint-disable */

import MapBoxLayerMixin from '@/components/common/MapBoxMixin'

import { dynamicReverseGeocode } from '@/services/geocoder'

import { LocationPickerDiameter } from '@/config'

/**
 * This layer handles the location selection
 */
export default {
  name: 'LayerLocationPicker',
  mixins: [ MapBoxLayerMixin ],
  props: {
    /**
     * Whether the location can still be changed
     */
    locked: {
      type: Boolean,
      default: false
    },

    /**
     * (Temporarily) freezes the interaction
     * Used while saving data to the server
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * Whether to emit a full address or just the coordinates
     */
    useGeocoder: {
      type: Boolean,
      default: true
    },
    
    /**
     * If a location was previously selected, coordinates are provided
     */
    coordinates: {
      type: Object,
      default: null
    },
    alternative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layerName: `${this.alternative ? 'alt' : ''}locationpin`,

      /**
       * Upon interaction, the new coordinates are stored here
       */
      location: null,

      history: [],

      /**
       * 
       */
      geocoderTimeout: null
    }
  },
  computed: {

    hasLocation() {
      return this.location !== null
    },

    /**
     * Used to calculate px size of the 200 meters circle
     */
    latitude() {
      return this.hasLocation ? this.location[1] : 52.3669317977
    }
  },
  watch: {
    /**
     * Init the layer when mapbox is done loading
     */
    // loaded() {
    //   if (this.loaded) {
    //     this.init()
    //   }
    // },

    /**
     * Update the location if new coordinates are passed through the props
     */
    coordinates(val) {
      const isCoordinatesChanged = (val && this.location) && Object.values(val).join() !== this.location.join()

      if (isCoordinatesChanged || (val && !this.location)) {
        this.location = [val.lng, val.lat]
        this.emitChange()
      }
    },

    /**
     * When the location changes
     */
    location(newLocation, oldLocation) {
      
      if (
        Array.isArray(newLocation) && 
        Array.isArray(oldLocation) && 
        oldLocation.length === newLocation.length && 
        newLocation[0] === oldLocation[0] &&
        newLocation[1] === oldLocation[1]
      ) {
        return
      }

      // Keep track of location changes (TODO: enable undo feature)
      if (oldLocation) {
        this.history.push(oldLocation)
      }

      if (! this.loaded) return
      let source = this.map.getSource(this.layerName)

      if (! source) return
      source.setData(this.generateGeoJson())

      // Placing the event emitter here avoids triggering an event while init hasn't finished
      if (this.geocoderTimeout) {
        clearTimeout(this.geocoderTimeout)
      }
      
      this.geocoderTimeout = setTimeout(this.emitChange, 300)
    },

    /**
     * Change the pointer style when the locked or disabled state of the component changes
     */
    locked() {
      if ( ! this.loaded) return

      this.map.getCanvas().style.cursor = this.disabled || this.locked ? '' : 'pointer'
    },
    disabled() {
      if ( ! this.loaded) return

      this.map.getCanvas().style.cursor = this.disabled || this.locked ? '' : 'pointer'
    }
  },
  created() {
    // If coordinates were passed, set the location
    if (this.coordinates) {
      this.location = [this.coordinates.lng, this.coordinates.lat]
    }

    if (this.loaded) {
      ['red', 'green'].forEach(icon => {
        this.map.loadImage(
          require(`@/assets/image/legend/map-pin-solid-${icon}.png`),
          (err, image) => {
            if (err) throw err;
            if (! this.map.hasImage(`map-pin-solid-${icon}`)) {
              this.map.addImage(`map-pin-solid-${icon}`, image);
            }
            this.init()
          }
        )
      })
    }
  },
  /**
   * Clean up
   */
  beforeDestroy() {
    // Remove mapBox event listeners //
    ['click', 'contextmenu'].forEach(event => this.map.off(event, this.handleEvent))
    
    this.clearLayer()
  },
  methods: {
    init() {
      // Initiate mapBox event listeners //
      ['click', 'contextmenu'].forEach(event => this.map.on(event, this.handleEvent))

      if (! this.locked && ! this.disabled) {
        this.map.getCanvas().style.cursor = 'pointer'
      }
      
      this.$nextTick(() => {
        this.addLayer()
      })
    },

    handleEvent (e) {
      // Atm., no difference between click and contextmenu events //
      e.originalEvent.ctrlKey && this.handleAddMarkerEvent(e)
    },

    /**
     * Clicking on the map changes the position
     */
    handleAddMarkerEvent(e) {
      if (this.locked || this.disabled || e._defaultPrevented) return
      
      this.location = e.lngLat.toArray()
    },

    /**
     * Whenever the location changes, emit the location
     *  Optionally after performing a reverse geocode address lookup
     */
    emitChange: async function() {
      if (! this.loaded || this.locked || this.disabled) return 

      let components = null

      if (this.useGeocoder) {
        try {
          components = await dynamicReverseGeocode({
            lng: this.location[0],
            lat: this.location[1]
          })
        } catch(e) {
          if (e === 'not found') {
            components = false
          } else {
            
            components = false
          }
        }
        this.$emit('location', {
          coordinates: this.location,
          components
        })
      }
      //console.log(this.useGeocoder)
    },

    /**
     * Generate the location pin geojson
     */
    generateGeoJson() {
      return {
        "type": "FeatureCollection",
        "features": this.hasLocation ? [{
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": this.location
          }
        }] : []
      }
    },

    /**
     * Source: https://stackoverflow.com/a/37794326
     */
    metersToPixelsAtMaxZoom({ meters, latitude }) {
      return meters / 0.075 / Math.cos(latitude * Math.PI / 180)
    },

    addLayer() {
      this.clearLayer()

      // Add the generated source 
      this.map.addSource(this.layerName, {
        type: 'geojson',
        data: this.generateGeoJson() 
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['chargingpoints', 'chargingpoints-text', 'requests-text', 'requests']
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      /**
       * The marker circle
       * TODO: switch to Isochrone
       */

        this.map.addLayer({ 
        "id": `${this.layerName}-circle`,
        "type": "circle",
        "source": this.layerName,
        "minzoom": 10,
        "layout": {},
        "paint": {
          "circle-radius": {
            stops: [
              [0, 0],
              [20, this.metersToPixelsAtMaxZoom({ 
                meters: LocationPickerDiameter, 
                latitude: this.latitude 
              })]
            ],
            base: 2
          },
          "circle-color": this.alternative ? 'green' : '#ec0000',
          "circle-opacity": 0.2
        }
      }, positionBelow) 


      /**
       * The marker icon layer
       */
      this.map.addLayer({ 
        "id": this.layerName,
        "type": "symbol",
        "source": this.layerName,
        "minzoom": 10,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          "icon-allow-overlap": true,
          "icon-image": this.alternative ? 'map-pin-solid-green' : 'map-pin-solid-red', 
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0.05,
            18,
            0.2
          ],
          "icon-offset": [0, -125]
        },
        "paint": {
          "icon-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            10.2,
            0.8
          ]
        }
      }) 

    }, 

    /**
     * Clean up the layer
     */
    clearLayer() {
      if (! this.map) return 

      let source = this.map.getSource(this.layerName)
      if (source) {
        if (this.map.getLayer(this.layerName)) {
          this.map.removeLayer(this.layerName)
        }
        if (this.map.getLayer(`${this.layerName}-circle`)) {
          this.map.removeLayer(`${this.layerName}-circle`)
        }
        this.map.removeSource(this.layerName)
      }
    }
  },

}
</script>


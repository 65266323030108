<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="hasAccess">
    <h3
      id="table_5"
      class="mt-3"
    >
      Netverzwaringen
    </h3>
    <div class="d-flex gap-1">
      <div
        v-for="(filter, key) in statusFilters.filters"
        :key="key"
        class="d-flex align-items-center"
      >
        <Button
          type="button"
          :class="selectedStatusFilter === key ? 'p-button-primary p-button-sm' : statusFilters.class"
          :label="filter.label"
          :badge="String(filter.count)"
          :badge-class="`p-badge-${selectedStatusFilter === key ? 'primary' : filter.badge || 'secondary'}`"
          @click="handleFilter({ filter: key })"
        />
      </div>
    </div>
    <div class="card mt-3">
      <data-table
        v-if="processes.length"
        data-key="uuid.value"
        :value="processes"
        class="processes-table p-datatable-sm"
        striped-rows
        sort-field="objectTypeSort"
        :sort-order="1"
      >
        <Column header="Stelsel">
          <template #body="{ data }">
            {{ data.objectType || '-' }}
          </template>
        </Column>
        <Column header="Klant">
          <template #body="{ data }">
            {{ data.customer }}
          </template>
        </Column>
        <Column header="Zuid-West">
          <template #body="{ data }">
            {{ data.zuidwest }}
          </template>
        </Column>
        <Column header="Zuid">
          <template #body="{ data }">
            {{ data.zuid }}
          </template>
        </Column>
        <Column header="Oost">
          <template #body="{ data }">
            {{ data.oost }}
          </template>
        </Column>
        <Column header="Noord-West">
          <template #body="{ data }">
            {{ data.noordwest }}
          </template>
        </Column>
        <Column header="Noord">
          <template #body="{ data }">
            {{ data.noord }}
          </template>
        </Column>
        <Column header="Totaal">
          <template #body="{ data }">
            {{ data.total }}
          </template>
        </Column>
      </data-table>
    </div>
  </div>
  <div v-else>
    U heeft geen toegang tot klantrapportages
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { mapGetters } from 'vuex';

export default {
  name: 'RVBGridImprovements',
  components: {
    DataTable,
    Column,
    Button
  },

  data() {
    return {
      processes: [],
      selectedStatusFilter: 'all',
      statusFilters: {
        filters: {
          all: { label: 'Alles', count: 0 },
          active: { label: 'Actief', count: 0 },
          onhold: { label: 'On hold', count: 0 },
          completed: { label: 'Afgerond', count: 0 },
          cancelled: { label: 'Geannuleerd', count: 0 }
        },
        class: 'p-button-sm p-button-outlined p-button-secondary',
      },
      regionToSlug: {},
      regions: [
        'Noord',
        'Noord-West',
        'Oost',
        'Zuid',
        'Zuid-West'
      ]
    }
  },

  computed: {
    ...mapGetters('processes', ['getProcessTableRecords']),
    ...mapGetters('user', [
      'securityCheck'
    ]),
    ...mapGetters('tenant', [
      'getLabelsByPidUuid'
    ]),
    hasAccess() {
      return this.securityCheck({ attribute: 'reporting' })
    },

  },

  watch: {
    getProcessTableRecords: {
      immediate: true,
      handler(value) {
        if (value.length) {
          const filtered = value.filter(record => record.powerOption.value === 'GridUpgrade')
          this.processes = this.dataPresentationMapper(filtered)

          this.statusFilters.filters.all.count = value.length
          this.setStatusFiltersCounts(filtered)
        }
      },
    },
  },

  methods: {
    mapObjectKeysToValue({ obj, value }) {
      return Object.keys(obj).reduce((result, key) => {
        result[key] = value
        return result
      }, {})
    },

    convertRegionToSlug({ region }) { return region.toLowerCase().replace('-', '').trim() },

    dataPresentationMapper(records) {
      this.regions.forEach(region => {
        let slug = this.convertRegionToSlug({ region })
        this.regionToSlug[slug] = region

      })

      const entry = Object.assign({
        objectType: '',
        customer: '',
        total: 0
      }, this.mapObjectKeysToValue({
        obj: this.regionToSlug,
        value: 0
      }))

      const data = {
        total: Object.assign({
          objectType: 'Totaal',
          objectTypeSort: 'ZZ',
          total: 0
        }, this.mapObjectKeysToValue({
          obj: this.regionToSlug,
          value: 0
        })),
      }

      records.forEach(record => {
        const objectType = record.objectType?.value
        const customer = record.customer?.value
        const region = record.region?.value
        const slug = this.convertRegionToSlug({ region })
        const id = `${objectType}-${customer}`

        if (!data[id]) {
          data[id] = Object.assign({}, entry, {
            customer,
            objectType,
            objectTypeSort: objectType
          })
        }

        data[id][slug] = data[id][slug] + 1
        data[id].total = data[id].total + 1
        data.total[slug] = data.total[slug] + 1
        data.total.total = data.total.total + 1
      })

      return Object.values(data)
    },

    setStatusFiltersCounts(records) {
      const data = records || this.processes
      Object.values(this.statusFilters.filters).forEach(filter => filter.count = 0)

      data.map(record => {
        const statuses = record.status.value
        Object.keys(statuses).forEach(status => statuses[status] ? this.statusFilters.filters[status].count++ : null)
      })
    },

    handleFilter({ filter }) {
      if (this.selectedStatusFilter === filter) return
      this.selectedStatusFilter = filter
      this.processes = this.dataPresentationMapper(this.getProcessTableRecords.filter(record => record.powerOption.value === 'GridUpgrade' && record.status.value[filter]))
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
}

:deep(.center .p-column-header-content) {
  justify-content: center;
}

:deep(.p-datatable .p-datatable-tbody > tr:last-child) {
  font-weight: bold;
}

:deep(.p-datatable thead) {
  position: sticky;
  top: 52px;

  th {
    border-bottom: 1px solid #999 !important;
    background: #eee !important;
  }
}

.gap-1 {
  gap: 1em;
}

.xsmall {
  font-size: 0.8em;
  color: #777
}
</style>
